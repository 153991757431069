import React, { Fragment } from 'react';
import ReactDOM from 'react-dom'

import { ipserver, Number_Format, CheckFileSize } from '../utils/Main.js';
const urlweb = ipserver;

// Create Modal Form
export const ModalCreate = class ModalCreate {
    constructor() {
        this.idmodal = ''
        this.create()
    }
    create() {
        // get count for id modal
        let ctmodal = window.state_modal_ct || 0
        ctmodal = ctmodal + 1
        window.state_modal_ct = ctmodal

        const elementModal = document.createElement('div')
        elementModal.id = `modal_${ctmodal}`
        document.getElementById('divmodal').appendChild(elementModal)
        this.idmodal = elementModal.id
    }
    attach(componentModal) {
        const idmodal = this.idmodal
        ReactDOM.render('', document.getElementById(idmodal));
        setTimeout(() => {
            ReactDOM.render(
                componentModal,
                document.getElementById(idmodal)
            );
        }, 100);
    }
    remove() {
        setTimeout(() => {
            var elem = document.getElementById(this.idmodal)
            elem.parentNode.removeChild(elem);
        }, 500);
    }
}

// Dialog Confirmation
class ComponentDialogConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            message: props.message,
            actionyes: props.actionyes,
            actionno: props.actionno,
        }
    }
    componentDidMount() {
        document.getElementById('btnCancelModal').focus();
        document.getElementById('btnCancelModal').blur();
    }
    actionYes(e) {
        e.preventDefault();
        this.props.actionyes(this);
    }
    actionNo(e) {
        e.preventDefault();
        this.props.actionno(this);
    }
    hide() {
        ReactDOM.render('', document.getElementById('divdialog'));
    }
    render() {
        return <div className="dialog-block d-flex justify-content-center align-items-center">

            <div className="card me-3 ms-3 shadow" style={{ borderRadius: '0px', width: '400px' }} >
                <div className="card-body" style={{ padding: '30px' }}>
                    <h3>{(this.state.title === '') ? 'Pesan Konfirmasi' : this.state.title}</h3>
                    <p>{this.state.message}</p>
                    <div className="d-flex justify-content-end">
                        <button id="btnCancelModal" className="btn btn-light me-2" style={{ background: 'transparent', borderColor: 'transparent', fontWeight: 'lighter' }} onClick={(e) => { this.actionNo(e); }}>Batal</button>
                        <button className="btn btn-light me-2" style={{ background: 'transparent', borderColor: 'transparent', fontWeight: 'lighter' }} onClick={(e) => { this.actionYes(e); }}>Oke</button>
                    </div>
                </div>
            </div>

        </div>
    }
}
export const ConfirmDialog = function (title, message, actionyes, actionno) {
    document.getElementById('divdialog').classList = '';
    document.getElementById('divdialog').style.position = 'relative'
    ReactDOM.render('', document.getElementById('divdialog'));
    setTimeout(() => {
        ReactDOM.render(
            <ComponentDialogConfirmation
                title={title}
                message={message}
                actionyes={actionyes}
                actionno={actionno}
            />,
            document.getElementById('divdialog')
        );
    }, 100);
}

// Dialog Confirmation
class ComponentDialogAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            message: props.message,
            actionyes: props.actionyes,
        }
    }
    componentDidMount() {
        document.getElementById('btnCancelModal').focus();
        document.getElementById('btnCancelModal').blur();
    }
    actionYes(e) {
        e.preventDefault();
        this.props.actionyes(this);
    }
    hide() {
        ReactDOM.render('', document.getElementById('divdialog'));
    }
    render() {
        return <div className="dialog-block d-flex justify-content-center align-items-center">

            <div className="card me-3 ms-3 shadow" style={{ borderRadius: '0px', width: '400px' }} >
                <div className="card-body" style={{ padding: '30px' }}>
                    <h3>{(this.state.title === '') ? 'Pesan Pemberitahuan' : this.state.title}</h3>
                    <p>{this.state.message}</p>
                    <div className="d-flex justify-content-end">
                        <button id="btnCancelModal" className="btn btn-light me-2" style={{ background: 'transparent', borderColor: 'transparent', fontWeight: 'lighter' }} onClick={(e) => { this.actionYes(e); }}>Oke</button>
                    </div>
                </div>
            </div>

        </div>
    }
}
export const AlertDialog = function (title, message, actionyes, actionno) {
    document.getElementById('divdialog').classList = '';
    document.getElementById('divdialog').style.position = 'relative'
    ReactDOM.render('', document.getElementById('divdialog'));
    setTimeout(() => {
        ReactDOM.render(
            <ComponentDialogAlert
                title={title}
                message={message}
                actionyes={actionyes}
            />,
            document.getElementById('divdialog')
        );
    }, 100);
}

// Toast
class ComponentToast extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: props.message,
            actionyes: props.actionyes,
        }
    }
    componentDidMount() {
        document.getElementById('btnCancelToast').focus();
        setTimeout(() => {
            this.actionYes(null);
        }, 2000);
    }
    actionYes(e) {
        if (e !== null) e.preventDefault();
        if (this.props.actionyes !== '') this.props.actionyes(this);
        else {
            this.hide();
        }
    }
    hide() {
        ReactDOM.render('', document.getElementById('divtoast'));
    }
    render() {
        return <div className="toast-block d-flex justify-content-center align-items-center" >
            <div className="card me-3 ms-3 bg-dark shadow" style={{ borderRadius: '0px', width: '100%' }} >
                <div className="card-body" style={{ padding: '10px 20px', color: 'white' }}>
                    <div className="d-flex flex-row-reverse justify-content-between align-items-center ">
                        <button id="btnCancelToast" className="btn btn-outline-light" style={{ fontWeight: 'lighter' }} onClick={(e) => { this.actionYes(e); }}>Tutup</button>
                        <p style={{ margin: '0px' }}>{this.state.message}</p>
                    </div>
                </div>
            </div>
        </div>
    }
}
export const Toast = function (message, actionyes) {
    document.getElementById('divtoast').style.position = 'static';
    ReactDOM.render('', document.getElementById('divtoast'));
    setTimeout(() => {
        ReactDOM.render(
            <ComponentToast
                message={message}
                actionyes={actionyes}
            />,
            document.getElementById('divtoast')
        );
    }, 100);
}

// Pagging Session
const EntityPagging = class {
    constructor(namestorage = '') {
        this.namestorage = namestorage
        this.set('sortcoumn_default', 'ID')
        this.set('search', '')
        this.set('count', 10)
        this.set('index', 1)
        this.set('sortcoumn', this.get('sortcoumn_default'))
        this.set('sortorder', 'asc')
    }
    set(key, value) {
        window.Storage[this.namestorage + '_' + key] = value
    }
    get(key) {
        return window.Storage[this.namestorage + '_' + key]
    }
    setdefaultcolumn(namecolumn) {
        this.set('sortcoumn_default', namecolumn)
        this.set('sortcoumn', this.get('sortcoumn_default'))
    }
    reset() {
        this.set('index', 1)
        this.set('sortcoumn', this.get('sortcoumn_default'))
        this.set('sortorder', 'asc')
    }
}
export { EntityPagging }

// Table
export const TableData = class ComponentTable extends React.Component {
    constructor(props) {
        super(props);
        this.modelData = new EntityPagging(props.nameModelData)
        this.state = {
            ctx: props.ctx,
            viewTbHeader: <tr></tr>,
            viewTbBody: <tr></tr>,
            viewPagging: '',
            tableData: props.tableData,
            dataset: props.dataset, // dataset data
            search: this.modelData.get('search'),
            searchcount: this.modelData.get('count'),
            ctpages: props.ctpages || 0, // jumlah halaman
            ctdata: props.ctdata || 0, // jumlah data
            clickRow: props.clickRow || function (e, data) { e.preventDefault() },
        };
    }
    componentDidMount() {
        this.renderTable();
    }
    componentDidUpdate(propsprev) {
        if (propsprev.dataset !== this.props.dataset) {
            this.setState({
                dataset: this.props.dataset || [],
                ctpages: this.props.ctpages || 0,
                ctdata: this.props.ctdata || 0,
            })
            setTimeout(() => {
                this.renderTable();
            }, 100);
        }
    }
    renderTable() {
        const thiself = this
        let keyIndexRow = 0
        const dataset = this.state.dataset;

        // ---> view table header
        const viewTitle = []
        const dataTitle = this.state.tableData.header;
        for (const i in dataTitle) {
            const item = dataTitle[i]

            const sort = item.sort || ''
            viewTitle.push(
                <th key={i} className={item.css || ''}
                    style={{ cursor: 'default' }}
                    onClick={(e) => { thiself.sortColumn(e, item.sort) }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {item.caption}
                        {(sort !== '') &&
                            <span className="material-icons" style={{ color: 'gray' }}>unfold_more</span>
                        }
                    </div>
                </th>
            )
        }
        this.setState({
            viewTbHeader: <tr key={keyIndexRow} className='table-light'>
                {viewTitle}
            </tr>
        })

        // ---> view table body
        const viewBody = []
        const dataBody = this.state.tableData.body;
        if (dataset.length > 0) {
            // render row data
            for (const i in dataset) {
                // render col data
                const viewCol = []
                for (const i2 in dataBody) {
                    const typeview = dataBody[i2].type || 'label'
                    if (typeview === 'view') {
                        viewCol.push(
                            <td key={i2} className={dataBody[i2].css || ''}>
                                {dataBody[i2].name(dataset[i])}
                            </td>
                        )
                    } else {
                        // default type view is label
                        viewCol.push(
                            <td key={i2} className={dataBody[i2].css || ''}
                                onClick={(e) => {
                                    e.index = i
                                    this.state.clickRow(e, dataset[i])
                                }} >
                                {dataset[i][dataBody[i2].name]}
                            </td>
                        )
                    }
                }

                keyIndexRow += 1
                viewBody.push(
                    <tr key={keyIndexRow}>
                        {viewCol}
                    </tr>
                )
            }
        } else {
            viewBody.push(<tr key={1}>
                <td colSpan={dataTitle.length}>
                    <div style={{ margin: '10px' }}>
                        <div className="alert alert-info mb-0" role="alert">
                            <b>Tidak ada data</b>
                        </div>
                    </div>
                </td>
            </tr>)
            // tidak ada data
        }
        this.setState({ viewTbBody: viewBody })


        // ---> view pagging
        if (dataset.length > 0) {
            let page = this.modelData.get('index') // posisi halaman
            let ctbuttonshow = 10 // jumlah button pagging yang ditampilkan
            let ctpage = this.state.ctpages // jumlah halaman
            let arr = []

            // get left 
            let ct = (parseInt(page) === 1) ? 2 : page
            for (let i = 0; i < (Math.floor(ctbuttonshow / 2)); i++) {
                if ((ct - 1) > 0) {
                    ct -= 1
                    arr.unshift(ct)
                }
            }

            // get rigt 
            let ctbuttonshowtemp = ctbuttonshow - arr.length
            let num = arr[arr.length - 1]
            for (let i = 0; i < ctbuttonshowtemp; i++) {
                num += 1
                if (num <= ctpage) {
                    arr.push(num)
                }
            }

            // if right loss, add left
            if (arr.length < ctpage) {
                if (arr.length < ctbuttonshow) {
                    let ctaddleft = ctbuttonshow - arr.length
                    let paggingfirst = arr[0]
                    for (let i = 0; i < ctaddleft; i++) {
                        paggingfirst -= 1
                        arr.unshift(paggingfirst)
                    }
                }
            }

            let viewPaggingItem = []
            for (let i = 0; i < arr.length; i++) {
                viewPaggingItem.push(
                    <button
                        key={i}
                        type="button"
                        className={(arr[i] === page) ? "btn btn-success" : "btn btn-outline-success"}
                        style={{ minWidth: '35px' }}
                        onClick={(e) => { this.changePagging(e, arr[i]) }}
                    >
                        {arr[i]}
                    </button>
                )
            }

            const counterdatafirst = (this.state.searchcount * page) - this.state.searchcount + 1
            const counterdataend = ((this.state.searchcount * page) - this.state.searchcount) + dataset.length
            this.setState({
                viewPagging: <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                    <div className="d-flex align-items-center">
                        URUTAN {counterdatafirst + ' - ' + counterdataend} DARI {this.state.ctdata || 0}
                    </div>
                    <div>
                        <div id="table-div-pagging" className="btn-group" role="group" >
                            <button type="button" className="btn btn-outline-success" onClick={(e) => { this.changePagging(e, 'prev') }}>Prev</button>
                            {viewPaggingItem}
                            <button type="button" className="btn btn-outline-success" onClick={(e) => { this.changePagging(e, 'next') }}>Next</button>
                        </div>
                    </div>

                </div>
            })
        }
    }
    changePagging(e, mode) {
        e.preventDefault();
        let flag = true;
        let indextemp = 1;
        switch (mode) {
            case 'prev': {
                indextemp = parseInt(this.modelData.get('index')) - 1;
                if (indextemp <= 0) {
                    flag = false;
                }
                break;
            }
            case 'next': {
                indextemp = parseInt(this.modelData.get('index')) + 1;
                if (indextemp >= (Math.ceil(this.state.ctpages) + 1)) {
                    flag = false;
                }
                break;
            }
            default:
                indextemp = mode
                break;
        }

        if (flag) {
            this.modelData.set('index', indextemp)
            this.state.ctx.previewData();
        } else {
            console.log('bypas pagging');
        }
    }
    sortColumn(e, columnsort) {
        e.preventDefault();

        if (columnsort !== '') {
            if (columnsort === this.modelData.get('sortcolumn')) {
                if (this.modelData.get('sortorder') === 'asc') {
                    this.modelData.set('sortorder', 'desc')
                } else {
                    this.modelData.set('sortorder', 'asc')
                }
                this.state.ctx.previewData();
            } else {
                this.modelData.set('sortcolumn', columnsort)
                this.modelData.set('sortorder', 'asc')
                this.state.ctx.previewData();
            }
        }
    }
    render() {
        return <div className='card' style={{ overflow: 'auto' }}>
            <table className="table table-hover">
                <tbody>
                    {this.state.viewTbHeader}
                    {this.state.viewTbBody}
                    <tr style={(this.state.viewPagging !== '') ? { display: 'table-row' } : { display: 'none' }}>
                        <td colSpan={this.state.tableData.header.length}  >
                            {this.state.viewPagging}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
};

// Session Data Table
export const state_pagging_get = {
    url: () => { return window.state_pagging_data_url },
    index: () => { return window.state_pagging_data_index },
    countdata: () => { return window.state_pagging_data_countdata },
    searchfilter: () => { return window.state_pagging_data_searchfilter },
    sortcolumn: () => { return window.state_pagging_data_sortcolumn },
    sortorder: () => { return window.state_pagging_data_sortorder }, // asc or desc
};
export const state_pagging_set = {
    url: (data) => { window.state_pagging_data_url = data; },
    index: (data) => { window.state_pagging_data_index = data; },
    countdata: (data) => { window.state_pagging_data_countdata = data; },
    searchfilter: (data) => { window.state_pagging_data_searchfilter = data; },
    sortcolumn: (data) => { window.state_pagging_data_sortcolumn = data; },
    sortorder: (data) => { window.state_pagging_data_sortorder = data; }, // asc or desc
};
const func_state_pagging = {
    set: state_pagging_set,
    get: state_pagging_get
}
export const state_pagging = func_state_pagging;

// Table // depreciated
export const Table = class ComponentTable extends React.Component {
    constructor(props) {
        super(props);
        const noaction = (e) => {
            e.preventDefault()
        }
        this.state = {
            ctx: props.ctx,
            viewColumnTable: <td></td>,
            viewContentTable: <tr><td></td></tr>,
            viewContentFooter: props.viewContentFooter || <tr></tr>,
            viewPaggingTable: '',
            tableConfig: props.tableConfig,
            search: state_pagging.get.searchfilter(),
            searchcount: state_pagging.get.countdata(),
            ctpagging: props.tableConfig.tableCt || 0,
            ctsummary: props.tableConfig.tableSummary || 0,

            setHideHeaderTable: props.tableConfig.hideheader || false,
            setHidePagging: props.tableConfig.hidepagging || false,

            tableOnClick: props.tableConfig.tableOnClick || noaction,
        };
    }
    handleChange(event, name) {
        if (name === 'searchcount') {
            if (event.target.value === '') this.setState({ [name]: 0 });
            else this.setState({ [name]: event.target.value });
        } else this.setState({ [name]: event.target.value });
        setTimeout(() => {
            func_state_pagging.set.searchfilter(this.state.search);
            func_state_pagging.set.countdata(this.state.searchcount);
            func_state_pagging.set.index(1);
        }, 100);
    }
    componentDidMount() {
        this.updateData();
    }
    componentDidUpdate(propsprev) {
        if (propsprev.tableConfig.tableData !== this.props.tableConfig.tableData) {
            this.setState({
                tableConfig: this.props.tableConfig,
                ctpagging: this.props.tableConfig.tableCt || 0,
                ctsummary: this.props.tableConfig.tableSummary || 0,
                viewContentFooter: this.props.viewContentFooter || <tr></tr>,
            })
            setTimeout(() => {
                this.updateData();
            }, 100);
        }
    }
    updateData() { // render element table
        const tableheader = this.state.tableConfig.tableNameColumn.map((item, index) => {
            if (item.caption === '') return <th key={index}></th>
            if (item.columnsort === '') {
                return <th key={index} style={{ verticalAlign: 'middle' }}>
                    <div style={{ cursor: 'default', width: '100%' }} >{item.caption}</div>
                </th>
            } else {
                return <th key={index} style={{ verticalAlign: 'middle' }}>
                    <div className="table-caption-hover d-flex justify-content-between align-items-center" style={{ cursor: 'pointer', width: '100%' }} onClick={(e) => { this.sortColumn(e, item.columnsort) }}>
                        {item.caption}
                        <span className="material-icons" style={{ color: 'gray' }}>unfold_more</span>
                    </div>
                </th>
            }
        })
        this.setState({ viewColumnTable: tableheader })

        let recno = parseInt(state_pagging.get.index()) * parseInt(state_pagging.get.countdata()) - parseInt(state_pagging.get.countdata());
        if (this.state.tableConfig.tableData.length > 0) {
            // content table
            const tablecontent = this.state.tableConfig.tableData.map((item, indextable) => {
                recno = recno + 1;



                const viewData = this.state.tableConfig.tableValue.map((itemvalue, index) => {
                    let onclicktemp = (e) => { }

                    if (itemvalue.actionclick === true) {
                        onclicktemp = (e) => {
                            this.state.tableOnClick(e, item, index)
                        }
                    }

                    let resulttemp = '';
                    switch (itemvalue.type) {
                        case 'recno':
                            resulttemp = <td style={{ verticalAlign: 'middle' }} key={index} onClick={(e) => { onclicktemp(e) }}>{recno}</td>
                            break;
                        case 'img-data':
                            resulttemp = <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }} key={index} onClick={(e) => { onclicktemp(e) }}><img className="img-thumbnail shadow-sm" src={urlweb + '/' + item[itemvalue.value]} style={{ minWidth: '100px', width: '100px', height: '100px', objectFit: 'contain' }} alt=""></img></td>
                            break;
                        case 'img-small-data':
                            resulttemp = <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }} key={index} onClick={(e) => { onclicktemp(e) }}><img className="img-thumbnail shadow-sm" src={urlweb + '/file/small_' + item[itemvalue.value]} style={{ minWidth: '100px', width: '100px', height: '100px', objectFit: 'contain' }} alt=""></img></td>
                            break;
                        case 'label-data':
                            resulttemp = <td style={{ verticalAlign: 'middle' }} key={index} onClick={(e) => { onclicktemp(e) }}><div className="text-warping">{item[itemvalue.value]}</div></td>
                            break;
                        case 'label-number-data':
                            resulttemp = <td style={{ verticalAlign: 'middle' }} key={index} onClick={(e) => { onclicktemp(e) }}>{Number_Format(item[itemvalue.value])}</td>
                            break;
                        case 'view':
                            resulttemp = <td style={{ verticalAlign: 'middle' }} key={index} onClick={(e) => { onclicktemp(e) }}>{itemvalue.value(item, index)}</td>
                            break;
                        case 'view-nowrap':
                            resulttemp = <td style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }} key={index} onClick={(e) => { onclicktemp(e) }}>{itemvalue.value(item)}</td>
                            break;
                        default: resulttemp = <td style={{ verticalAlign: 'middle' }} key={index} onClick={(e) => { onclicktemp(e) }}></td>
                            break;
                    }
                    return resulttemp;
                })

                return <tr className="" key={indextable}  >
                    {viewData}
                </tr>;
            })

            // pagging
            let pages = Math.ceil(this.state.tableConfig.tableCt)
            let limit = 10
            let page = state_pagging.get.index()

            let buttonPagging = []

            let recnopage = 1
            for (let i = 0; i < limit; i++) {
                if ((recnopage - 1) < pages) {
                    buttonPagging[i] = recnopage
                    recnopage = recnopage + 1
                }
            }

            let paggingitem = [];
            for (let i = 0; i < buttonPagging.length; i++) {
                if (buttonPagging[i] === page) {
                    paggingitem.push(
                        <button key={i} type="buttonchangePagging " className="btn btn-secondary" style={{ minWidth: '35px' }} onClick={(e) => { this.changePagging(e, buttonPagging[i]) }}>{buttonPagging[i]}</button>
                    );
                } else {
                    paggingitem.push(
                        <button key={i} type="buttonchangePagging " className="btn btn-outline-secondary" style={{ minWidth: '35px' }} onClick={(e) => { this.changePagging(e, buttonPagging[i]) }}>{buttonPagging[i]}</button>
                    );
                }
            }

            const counterdatafirst = (this.state.searchcount * page) - this.state.searchcount + 1
            const counterdataend = ((this.state.searchcount * page) - this.state.searchcount) + this.state.tableConfig.tableData.length

            this.setState({
                viewContentTable: tablecontent,
                viewPaggingTable: <div className="d-flex justify-content-between mb-4">

                    <div className="d-flex align-items-center" >
                        Urutan {counterdatafirst + ' - ' + counterdataend} dari {this.state.tableConfig.tableCtData || 0}
                    </div>
                    <div>
                        <div id="table-div-pagging" className="btn-group" role="group" >
                            <button type="button" className="btn btn-outline-secondary" onClick={(e) => { this.changePagging(e, 'prev') }}>Prev</button>
                            {paggingitem}
                            <button type="button" className="btn btn-outline-secondary" onClick={(e) => { this.changePagging(e, 'next') }}>Next</button>

                        </div>
                        {(this.state.activeBtnExportExcel === true) &&
                            <button className="btn btn-secondary ms-2" onClick={(e) => { this.state.ctx.exportToExcel(e) }}>
                                <span className="material-icons materialdesign-textsize" style={{ verticalAlign: 'middle' }}>
                                    download
                                </span>
                            </button>
                        }
                    </div>

                </div>
            })
        } else {
            this.setState({
                viewContentTable: <tr><td colSpan={this.state.tableConfig.tableValue.length}>Tidak ada data</td></tr>,
                viewPaggingTable: ''
            })
        }

    }
    changePagging(e, mode) {
        e.preventDefault();
        let flag = true;
        let indextemp = 1;
        if (mode === 'prev') {
            indextemp = parseInt(state_pagging.get.index()) - 1;
            if (indextemp <= 0) flag = false;
        } else if (mode === 'next') {
            indextemp = parseInt(state_pagging.get.index()) + 1;
            console.log(Math.ceil(this.state.ctpagging) + 1);
            if (indextemp >= (Math.ceil(this.state.ctpagging) + 1)) flag = false;
        } else indextemp = mode;

        if (flag) {
            state_pagging.set.index(indextemp);
            this.state.ctx.previewData();
        }
    }
    sortColumn(e, columnsort) {
        e.preventDefault();
        if (columnsort !== '') {
            if (columnsort === state_pagging.get.sortcolumn()) {
                if (state_pagging.get.sortorder() === 'asc') state_pagging.set.sortorder('desc')
                else state_pagging.set.sortorder('asc')
                this.state.ctx.previewData();
            } else {
                state_pagging.set.sortcolumn(columnsort)
                state_pagging.set.sortorder('asc')
                this.state.ctx.previewData();
            }
        }
    }
    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.state.ctx.previewData();
        }
    }
    render() {
        return <Fragment>
            {
                this.state.setHideHeaderTable === false &&
                <Fragment>
                    <div className="row mb-2" id="table-div-search">
                        <div className="col-md-2 mb-2">
                            <label className="form-label">Tampilkan</label>
                            <select className="form-select " value={this.state.searchcount} style={{ width: '80px' }} onChange={(e) => { this.handleChange(e, 'searchcount') }}>
                                <option value="10" >10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <div className="col"></div>
                        <div className="col-md-4 mb-2">
                            <label className="form-label">Pencarian</label>
                            <div className="input-group " >
                                <input type="search" className="form-control " value={this.state.search} onChange={(e) => { this.handleChange(e, 'search') }} onKeyPress={(e) => { this.handleKeyDown(e); }} ></input>
                                <button className="btn btn-dark" onClick={() => { this.state.ctx.previewData(); }}>Tampilkan</button>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }


            <div style={{ overflow: 'auto' }}>
                <table className="table table-bordered table-striped table-hover mt-2">
                    <thead className="table-dark">
                        <tr>
                            {this.state.viewColumnTable}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.viewContentTable}
                        {this.state.viewContentFooter}
                    </tbody>
                </table>

                {
                    this.state.setHidePagging === false &&
                    <Fragment>{this.state.viewPaggingTable}</Fragment>
                }
            </div>
        </Fragment>
    }
};


// Input Data
export const InputData = class ComponentInputText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeinput: props.typeinput,
            label: props.label,
            placeholder: props.placeholder || '',
            dataname: props.dataname,
            required: props.required,

            idcomponent: props.idcomponent || '',
            itemsselect: props.itemsselect || [],
            valuetrue: props.valuetrue || '',
            valuefalse: props.valuefalse || '',
        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        this.setState({ dataname: e.target.value });
        this.props.onChange(e)
    }
    componentDidUpdate(propsPrev) {
        if (propsPrev.dataname !== this.props.dataname) {
            this.setState({ dataname: this.props.dataname })
        }
    }
    chooseImage() {
        document.getElementById('edtInputImage').click();
    }
    uploadImage(e) {
        e.preventDefault();
        const checkfile = CheckFileSize(document.querySelector('#edtInputImage'));
        if (checkfile !== 'true') {
            Toast(checkfile, '');
        } else {
            let input = document.querySelector('#edtInputImage');
            let dataImg = new FormData()
            dataImg.append('myfile', input.files[0])

            fetch(urlweb + '/upload', {
                method: 'POST',
                body: dataImg // This is your file object
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'true') {
                        this.props.onUpdateData(data.link)
                        document.querySelector('#imgView').src = urlweb + '/file/' + data.link
                    } else {
                        this.props.onUpdateData('')
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }
    render() {
        if (this.state.typeinput === 'submitcancel') {
            return <div className="row mb-2 mt-2">
                <div className="col-md-2"></div>
                <div className="col">
                    <div className="d-flex">
                        <button type="submit" className="btn btn-primary me-1">Simpan</button>
                        <button type="button" className="btn btn-secondary" onClick={this.props.onClick}>Batalkan</button>
                    </div>
                </div>
            </div>
        } else if (this.state.typeinput === 'switch') {
            return <div className="row mb-2 mt-2">
                <div className="col-md-2">{this.state.label}</div>
                <div className="col-md-4">
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" checked={this.state.dataname === this.state.valuetrue} onChange={(e) => {
                            if (e.target.checked === true) {
                                e.target.value = this.state.valuetrue
                            } else {
                                e.target.value = this.state.valuefalse
                            }
                            this.props.onChange(e)
                        }} />
                        {/* <label className="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label> */}
                    </div>
                </div>
            </div>
        } else if (this.state.typeinput === 'radio') {
            let divradio = []
            const itemselectTemp = this.state.itemsselect
            for (let i = 0; i < itemselectTemp.length; i++) {
                const idradio = `${this.state.idcomponent}_${i}`
                divradio.push(
                    <Fragment key={i}>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name={this.state.idcomponent} checked={this.state.dataname === itemselectTemp[i].value} value={itemselectTemp[i].value} onChange={(e) => { this.props.onChange(e) }} id={idradio} />
                            <label className="form-check-label" htmlFor={idradio}>
                                {itemselectTemp[i].name}
                            </label>
                        </div>
                    </Fragment>
                )
            }
            return <div className="row mb-2 mt-2">
                <div className="col-md-2">{this.state.label}</div>
                <div className="col-md-6">
                    {divradio}
                </div>
            </div>
        } else if (this.state.typeinput === 'select') {
            let divselect = []
            const itemselectTemp = this.state.itemsselect
            for (let i = 0; i < itemselectTemp.length; i++) {
                if (itemselectTemp[i].disable === true) {
                    divselect.push(<option key={i} value={itemselectTemp[i].value} disabled>{itemselectTemp[i].name}</option>)
                } else {
                    divselect.push(<option key={i} value={itemselectTemp[i].value}>{itemselectTemp[i].name}</option>)
                }
            }
            return <div className="row mb-2 mt-2">
                <div className="col-md-2">{this.state.label} </div>
                <div className="col-md-8">

                    <select className="form-select" value={this.state.dataname} onChange={(e) => { this.props.onChange(e) }} >
                        {divselect}
                    </select>

                </div>
            </div>
        } else if (this.state.typeinput === 'textarea') {
            return <div className="row mb-2">
                <div className="col-md-2 mb-1">{this.state.label}</div>
                <div className="col-md-8 mb-1">
                    {(this.state.required === true)
                        ? <textarea rows="5" type={this.state.typeinput} className="form-control" placeholder={this.state.placeholder} defaultValue={this.state.dataname} onChange={(e) => { this.props.onChange(e) }} required />
                        : <textarea rows="5" type={this.state.typeinput} className="form-control" placeholder={this.state.placeholder} defaultValue={this.state.dataname} onChange={(e) => { this.props.onChange(e) }} />
                    }
                </div>
            </div>
        } else if (this.state.typeinput === 'inputimg') {
            return <div className="row mb-2 mb-1">
                <div className="col-md-2 mb-1">{this.state.label}</div>
                <div className="col">
                    <input id="edtInputImage" accept="image/*" type="file" style={{ display: 'none' }} onChange={(e) => { this.uploadImage(e) }}></input>
                    <img id="imgView" className="img-thumbnail mb-1" style={{ width: '200px', height: '200px', objectFit: 'contain' }} alt="" onClick={this.chooseImage}></img>
                </div>
            </div>
        } else {
            return <div className="row mb-2">
                <div className="col-md-2 mb-1">{this.state.label}</div>
                <div className="col-md-8 mb-1">
                    {(this.state.required === true)
                        ? <input type={this.state.typeinput} className="form-control" placeholder={this.state.placeholder} value={this.state.dataname} onChange={(e) => { this.handleChange(e) }} required />
                        : <input type={this.state.typeinput} className="form-control" placeholder={this.state.placeholder} value={this.state.dataname} onChange={(e) => { this.handleChange(e) }} />
                    }
                </div>
            </div>
        }
    }
};