import React, { Fragment } from 'react';
import {
  Routes,
  Route,
  Outlet
} from 'react-router-dom';

import { ipserver, GetCokkiee, DateInputNow, RequestHttp, CheckFileSize } from '../utils/Main.js';
import { ConfirmDialog, AlertDialog, Toast, TableData, ModalCreate, EntityPagging } from '../component/Component.js';
import { ModalSearchPromoForTemplate, ModalNotifMemberMultiple, ModalSearchCategoryPromo } from '../component/Modal.js';


class ViewMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewTable: 'loading...',

    };
  }
  componentDidMount() {
  }
  render() {
    return (
      <div style={{ padding: '10px' }}>
        <h5>Menu Promo</h5>

        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="card myhover">
              <div className="card-body text-secondary">
                Nonaktif
                <h2 className="text-dark" style={{ fontWeight: 'bolder' }} onClick={(e) => { this.filterData(e, 'Belum Aktif') }}>{this.state.summary_notactive}</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card myhover">
              <div className="card-body text-secondary">
                Aktif
                <h2 className="text-dark" style={{ fontWeight: 'bolder' }} onClick={(e) => { this.filterData(e, 'Aktif') }}>{this.state.summary_active}</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card myhover">
              <div className="card-body text-secondary">
                Kadaluarsa
                <h2 className="text-dark" style={{ fontWeight: 'bolder' }} onClick={(e) => { this.filterData(e, 'Kadaluarsa') }}>{this.state.summary_expired}</h2>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}

export default ViewMenu;