import React, { Fragment } from 'react';
import {
  Route,
  Routes,
  useParams,
  useNavigate,
} from 'react-router-dom';

import { ipserver, CheckFileSize, GetCokkiee, RequestHttp } from '../utils/Main.js';
import { ConfirmDialog, AlertDialog, Toast, TableData, ModalCreate, EntityPagging } from '../component/Component.js';
import { ModalSearchCategoryProduct } from '../component/Modal.js';
import { EntityUnit } from '../component/Entity';
import imgnotfound from '../assets/notfound.png';
const urlweb = ipserver;


const nameEntityPagging = 'table_master_product'
function App() {
  let navigate = useNavigate();

  // browse data
  class ViewBrowse extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        viewTable: 'loading...',
        search: '',
      };
      this.modelData = new EntityPagging(nameEntityPagging)
    }
    componentDidMount() {
      this.previewData();
    }
    handleChange(e, name) {
      this.setState({ [name]: e.target.value });
      if (name === 'searchcount') {
        this.modelData.set('count', e.target.value);
        this.previewData()
      }
    }

    deleteData(e, iddata) {
      e.preventDefault();
      const ctx = this;
      ConfirmDialog('', 'Anda yakin ingin menghapus data ini ?',
        (ctxdialog) => {
          const param = {
            token: GetCokkiee('token'),
            iddata: iddata
          }
          RequestHttp('/api_admin_v1/delete_product', param,
            function (data) {
              ctxdialog.hide();
              setTimeout(() => {
                if (data.status === 'true') {
                  ctx.modelData.reset()
                  ctx.previewData();
                  Toast(data.message, (ctxalert) => { ctxalert.hide(); });
                } else if (data.status === 'false') {
                  Toast(data.message, '');
                } else {
                  Toast('Terjadi Kesalahan', '');
                }
              }, 100);
            }, function (error) {
              console.log(error);
            }
          );
        }, (ctxdialog) => { // action no
          ctxdialog.hide();
        }
      );
    }
    previewData() {
      const ctx = this;
      RequestHttp('/api_admin_v1/list_product', {
        search: this.modelData.get('search'),
        searchcount: this.modelData.get('count'),
        index: this.modelData.get('index'),
        sortcolumn: this.modelData.get('sortcoumn'),
        sortorder: this.modelData.get('sortorder'),
      }, (data) => {
        if (data.status === 'true') {
          ctx.tableContent(data)
        } else if (data.status === 'false') {
          Toast(data.message, '');
        } else {
          Toast('Terjadi kesalahan, silahkan coba lagi', '');
        }
      }, (error) => {
        console.log(error);
      });
    }
    tableContent(data) {
      const ctx = this;

      const tdImg = function (itemdata) {
        return <Fragment>
          <img className="img-thumbnail shadow-sm"
            src={urlweb + '/api_module/file_product/' + itemdata.Img}
            style={{ minWidth: '100px', width: '100px', height: '60px', objectFit: 'cover' }}
            onError={(e) => { e.target.src = imgnotfound; }}
            alt="" />
        </Fragment>
      }
      const tdAction = function (itemdata) {
        return <Fragment>
          <button className="btn btn-outline-light btn-border-none"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/master_product/input/${itemdata.ID}`)
            }}>
            <span className="material-icons d-flex" style={{ color: 'grey' }}>
              edit
            </span>
          </button>
          <button className="btn btn-outline-light btn-border-none"
            onClick={(e) => { ctx.deleteData(e, itemdata.ID) }}>
            <span className="material-icons d-flex" style={{ color: 'grey' }}>
              delete
            </span>
          </button>
        </Fragment>
      }
      const clickRow = function (e, item) {
        e.preventDefault();
        navigate(`/master_product/input/${item.ID}`)
      }

      const tableData = {
        header: [
          { caption: 'Action' },
          { caption: 'Image', sort: '' },
          { caption: 'Kode Produk', sort: '' },
          { caption: 'Nama Produk', sort: '' },
          { caption: 'Tanggal Buat', sort: '' },
          { caption: 'Tanggal Edit', sort: '' },
          { caption: 'Admin', sort: '' },
        ],
        body: [
          { name: tdAction, type: 'view', css: 'td-actions' },
          { name: tdImg, type: 'view', click: true },
          { name: 'CodeProduct', click: true },
          { name: 'NameProduct', click: true },
          { name: 'TimeCreated1', click: true },
          { name: 'TimeUpdated1', click: true },
          { name: 'NameAdmin', click: true },
        ],
      }

      this.setState({
        viewTable: <TableData
          ctx={this}
          nameModelData={nameEntityPagging}
          tableData={tableData}
          dataset={data.data}
          ctpages={data.ctpages}
          ctdata={data.ctdata}
          clickRow={clickRow}
        />
      });
    }
    render() {
      return (
        <div className='background-input' style={{ padding: '30px' }}>

          {/* search */}
          <div className="row justify-content-between">
            <div className="col-12 col-md-3 mb-1">
              <button
                className="btn btn-warning text-light"
                onClick={(e) => {
                  e.preventDefault()
                  this.modelData.reset()
                  navigate(`/master_product/input`)
                }}>
                Tambah Produk
              </button>
            </div>
            <div className="col-12 col-md-4 mb-1">
              <div className="input-group">
                <select className="form-select"
                  value={this.state.searchcount}
                  onChange={(e) => { this.handleChange(e, 'searchcount') }}
                  style={{ maxWidth: '80px' }}
                >
                  <option value="10" >10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <input type="text" className="form-control" placeholder="Pencarian"
                  value={this.state.search}
                  onChange={(e) => { this.handleChange(e, 'search') }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.modelData.reset()
                      this.modelData.set('search', this.state.search)
                      this.previewData()
                    }
                  }}
                />
                <button className='btn btn-warning text-light'
                  onClick={(e) => {
                    e.preventDefault()
                    this.modelData.reset()
                    this.modelData.set('search', this.state.search)
                    this.previewData()
                  }}
                >
                  <span className="material-icons d-flex">
                    search
                  </span>
                </button>
              </div>
            </div>

          </div>
          {/* table content */}
          <div className="mt-3">
            {this.state.viewTable}
          </div>

        </div >
      )
    }
  }

  // input data
  class ComponentInputUnit extends React.Component {
    constructor(props) {
      super(props);
      const dataunit = props.dataunit
      this.state = {
        NameUnit: dataunit.NameUnit,
        Rank: dataunit.Rank,
        Qty: dataunit.Qty,
        Price: dataunit.Price
      }
      this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event, name) {
      this.setState({ [name]: event.target.value });
      this.props.onChange(name, event.target.value)
    }
    deleteUnit(e) {
      e.preventDefault()
      const ctx = this
      ConfirmDialog('', 'Hapus data unit ?',
        (ctxdialog) => {
          ctxdialog.hide();
          ctx.props.deleteUnit()
        }, (ctxdialog) => { // action no
          ctxdialog.hide();
        }
      );
    }
    render() {
      return <tr>
        <td><button type='button' className='btn btn-warning text-light' onClick={(e) => { this.deleteUnit(e) }}>Delete</button></td>
        <td><input type="text" className='form form-control' value={this.state.NameUnit} onChange={(e) => { this.handleChange(e, 'NameUnit') }}></input></td>
        <td><input type="number" className='form form-control' value={this.state.Qty} onChange={(e) => { this.handleChange(e, 'Qty') }}></input></td>
        <td><input type="number" className='form form-control' value={this.state.Price} onChange={(e) => { this.handleChange(e, 'Price') }}></input></td>
      </tr>
    }
  }
  class InputData extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        viewUnitProduct: 'loading...',

        mode: props.mode,
        iddata: props.iddata,
        code: '',
        name: '',
        desc: '',
        idcategory: '',
        namecategory: '',
        img: '',

        datasetunit: [],
      };
      this.handleChange = this.handleChange.bind(this);
      this.uploadImage = this.uploadImage.bind(this);
    }
    componentDidMount() {
      if (this.state.mode === 'edit') {
        const ctx = this
        const param = {
          iddata: this.state.iddata
        }
        RequestHttp('/api_admin_v1/view_product', param,
          function (data) {
            if (data.status === 'true') {
              if (data.data.length > 0) {
                console.log(data.datasetunit);
                ctx.setState({
                  code: data.data[0].CodeProduct,
                  name: data.data[0].NameProduct,
                  desc: data.data[0].Description,
                  idcategory: data.data[0].IDCategory,
                  namecategory: data.data[0].NameCategory,
                  img: data.data[0].Img,
                  datasetunit: data.datasetunit,
                })
                document.querySelector('#imgView').src = `${urlweb}/api_module/file_product/${data.data[0].Img}`
                setTimeout(() => {
                  ctx.unitShow()
                }, 100);
              } else {
                AlertDialog('', 'Data tidak ditemukan', (ctxdialog) => {
                  ctxdialog.hide();
                  navigate(`/master_product`)
                });
              }
            } else if (data.status === 'false') {
              Toast(data.message, '')
            }
          }, function (error) {
            console.log(error);
          })
      } else {
        document.querySelector('#imgView').src = imgnotfound
        this.unitShow()
      }
    }
    handleChange(event, name) {
      this.setState({ [name]: event.target.value });
    }

    searchCategory(e) {
      e.preventDefault();
      const initModal = new ModalCreate()
      initModal.attach(<ModalSearchCategoryProduct
        closeModal={() => {
          initModal.remove()
        }}

        chooseData={(dataCategory) => {
          this.setState({
            idcategory: dataCategory.ID,
            namecategory: dataCategory.NameCategory,
          })
        }}

      />)
    }

    unitShow() {
      let viewTempUnitProduct = []
      const dataunit = this.state.datasetunit
      const ctx = this
      for (const i in dataunit) {
        viewTempUnitProduct.push(
          <ComponentInputUnit dataunit={dataunit[i]}
            onChange={(name, value) => {
              const item = ctx.state.datasetunit
              item[i][name] = value
              ctx.setState({ datasetunit: item })
            }}
            deleteUnit={() => {
              const item = ctx.state.datasetunit
              item.splice(i, 1)
              ctx.setState({ datasetunit: item })
              ctx.unitShow()
            }}
          />
        )
      }

      this.setState({
        viewUnitProduct: <Fragment>
          <table className="table table-bordered table-hover mt-2">
            <thead className="table-light">
              <tr>
                <th>Action</th>
                <th>Satuan Unit</th>
                <th>Jumlah</th>
                <th>Harga </th>
              </tr>
            </thead>
            <tbody>
              {viewTempUnitProduct}
            </tbody>
          </table>
        </Fragment>
      })
    }
    unitAdd(e) {
      e.preventDefault()
      const datasetUnit = this.state.datasetunit
      const itemUnit = new EntityUnit()
      datasetUnit.push(itemUnit)
      this.unitShow()
    }

    saveData(e) {
      e.preventDefault();
      console.log(this.state.datasetunit);
      let flagCheckUnit = true
      let qtyunitbefore = 0
      for (const i in this.state.datasetunit) {
        qtyunitbefore = (this.state.datasetunit[i - 1] !== undefined) ? this.state.datasetunit[i - 1].Qty : 0
        if (this.state.datasetunit[i].Qty <= qtyunitbefore) {
          flagCheckUnit = false
          break
        }
      }

      if (!flagCheckUnit) {
        Toast('Qty Unit harus lebih besar dari unit sebelumnya', '')
      } else {

        ConfirmDialog('', 'Anda yakin ingin menyimpan data ini ?',
          (ctxdialog) => {
            const urlinput = (this.state.mode === 'add') ? '/api_admin_v1/insert_product' : '/api_admin_v1/update_product';
            const param = {
              token: GetCokkiee('token'),
              iddata: this.state.iddata,
              code: this.state.code,
              name: this.state.name,
              desc: this.state.desc,
              idcategory: this.state.idcategory,
              img: this.state.img,
              datasetunit: this.state.datasetunit,
            }
            RequestHttp(urlinput, param,
              function (data) {
                ctxdialog.hide();
                setTimeout(() => {
                  if (data.status === 'true') {
                    Toast(data.message, '');
                    navigate(`/master_product`)
                  } else {
                    Toast(data.message, '');
                  }
                }, 100);
              }, function (error) {
                console.log(error);
              }
            );
          }, (ctxdialog) => { // action no
            ctxdialog.hide();
          }
        );
      }

    }
    uploadImage(e) {
      e.preventDefault();
      const checkfile = CheckFileSize(document.querySelector('#edtInputImage'));
      if (checkfile !== 'true') {
        Toast(checkfile, '');
      } else {
        let input = document.querySelector('#edtInputImage');
        let dataImg = new FormData()
        dataImg.append('myfile', input.files[0])
        dataImg.append('modeupload', 'product')

        fetch(`${urlweb}/api_module/upload`, {
          method: 'POST',
          body: dataImg // This is your file object
        })
          .then(response => response.json())
          .then(data => {
            if (data.status === 'true') {
              const img = data.link
              this.setState({ img: img })
              document.querySelector('#imgView').src = `${urlweb}/api_module/file_product/${img}`
            } else {
              this.setState({ img: '' })
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    }
    render() {
      return (
        <div className='background-input' style={{ padding: '30px' }}>
          <h4 style={{ fontWeight: 'bold' }}>{(this.state.mode === 'add') ? 'Tambah' : 'Edit'} Produk</h4>
          <div className="card">
            <div className="card-body">
              <form onSubmit={(e) => { this.saveData(e) }}>

                {/* Form Inputing */}
                <div className="row">

                  <div className='col-12 col-md-6'>
                    <div>Kode Produk</div>
                    <div className="mt-1 mb-2">
                      <input type="text" className="form-control" placeholder="Masukan Kode Produk" value={this.state.code} onChange={(e) => { this.handleChange(e, 'code') }} required />
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div>Nama Produk</div>
                    <div className="mt-1 mb-2">
                      <input type="text" className="form-control" placeholder="Masukan Nama Produk" value={this.state.name} onChange={(e) => { this.handleChange(e, 'name') }} required />
                    </div>
                  </div>

                  <div className='col-12'>
                    <div>Kategori</div>
                    <div className="mt-1 mb-2">
                      <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder="Kategori" value={this.state.namecategory} onChange={(e) => { this.handleChange(e, 'namecategory') }} disabled={true} required />
                        <button type='button' className='btn btn-secondary' onClick={(e) => { this.searchCategory(e) }}>Cari</button>
                      </div>
                    </div>
                  </div>


                  {/* <div className="col-12 ">Deskripsi</div>
                  <div className="col-12 mb-2">
                    <textarea className="form-control" placeholder="Masukan Deskripsi" rows="5" value={this.state.desc} onChange={(e) => { this.handleChange(e, 'desc') }} />
                  </div> */}

                  <div className='col-12'>
                    <div>Uploads Image</div>
                    <div className="mt-1 mb-2">
                      <input id="edtInputImage" accept="image/*" type="file" style={{ display: 'none' }} onChange={(e) => { this.uploadImage(e) }}></input>
                      <img id="imgView" className="img-thumbnail mb-1" style={{ width: '200px', height: '120px', objectFit: 'fill' }} alt=""
                        onClick={() => { document.getElementById('edtInputImage').click(); }}
                        onError={(e) => { e.target.src = imgnotfound; }}
                      />
                    </div>
                  </div>


                  <div className='col-12 mt-3 mb-2'>
                    <button className='btn btn-warning text-light' onClick={(e) => { this.unitAdd(e) }}>Tambah Unit</button>
                    {this.state.viewUnitProduct}
                  </div>

                  <div className="col-12 mt-4 d-flex">
                    <button type="submit" className="btn btn-warning text-light me-1" >Simpan</button>
                    <button type="button" className="btn btn-outline-warning"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/master_product`)
                      }}
                    >Batalkan</button>
                  </div>
                </div>
                {/* Form Inputing */}

              </form>
            </div>
          </div>
        </div >
      )
    }
  }
  function EditData() {
    let { id } = useParams();
    return <InputData mode="edit" iddata={id} />
  }

  return (
    <Routes>
      <Route index element={<ViewBrowse />} />
      <Route path={`/input`} element={<InputData mode="add" iddata="0" />} />
      <Route path={`/input/:id`} element={<EditData />} />
    </Routes>
  )
}

export default App;