import React, { Fragment } from 'react';
import {
  Route,
  Routes,
  useParams,
  useNavigate,
} from 'react-router-dom';

import { ipserver, GetCokkiee, RequestHttp } from '../utils/Main.js';
import { ConfirmDialog, AlertDialog, Toast, TableData, ModalCreate, EntityPagging } from '../component/Component.js';
import { ModalSearchMember } from '../component/Modal.js';
const urlweb = ipserver;


const nameEntityPagging = 'table_master_broadcast'
function App() {
  let navigate = useNavigate();

  // browse data
  class ViewBrowse extends React.Component {
    constructor(props) {
      super(props);
      this.modelData = new EntityPagging(nameEntityPagging)
      this.state = {
        viewTable: 'loading...',
        search: this.modelData.get('search') || '',
      };
    }
    componentDidMount() {
      this.previewData();
    }
    handleChange(e, name) {
      this.setState({ [name]: e.target.value });
      if (name === 'searchcount') {
        this.modelData.set('count', e.target.value);
        this.previewData()
      }
    }
    previewData() {
      const ctx = this
      RequestHttp('/api_admin_v1/list_broadcast', {
        search: this.modelData.get('search'),
        searchcount: this.modelData.get('count'),
        index: this.modelData.get('index'),
        sortcolumn: this.modelData.get('sortcoumn'),
        sortorder: this.modelData.get('sortorder'),
        filterdata: this.state.filterdata
      }, (data) => {
        if (data.status === 'true') {
          ctx.tableContent(data)
        } else if (data.status === 'false') {
          Toast(data.message, '')
        } else {
          Toast('Terjadi Kesalahan', '')
        }
      }, (error) => {
        console.log(error);
      });
    }
    tableContent(data) {
      const tdAction = function (itemdata) {
        const ctx = this
        return <Fragment>
          <button className="btn btn-outline-light btn-border-none"
            onClick={(e) => { ctx.deleteData(e, itemdata.ID) }}>
            <span className="material-icons d-flex" style={{ color: 'grey' }}>
              delete
            </span>
          </button>
        </Fragment>
      }

      const clickRow = function (e, item) {
        e.preventDefault();
        navigate(`/master_broadcast/input/${item.ID}`)
      }

      const tableData = {
        header: [
          { caption: 'Action' },
          { caption: 'Judul Broadcast', sort: '' },
          { caption: 'Deskripsi', sort: '' },
          { caption: 'Tanggal Dibuat', sort: '' },
          { caption: 'Tanggal Edit', sort: '' },
          { caption: 'Admin', sort: '' },
        ],
        body: [
          { name: tdAction, type: 'view', css: 'td-actions' },
          { name: 'Title', click: true },
          { name: 'Description', click: true },
          { name: 'TimeCreated1', click: true },
          { name: 'TimeUpdated1', click: true },
          { name: 'NameAdmin', click: true },
        ],
      }

      this.setState({
        viewTable: <TableData
          ctx={this}
          nameModelData={nameEntityPagging}
          tableData={tableData}
          dataset={data.data}
          ctpages={data.ctpages}
          ctdata={data.ctdata}
          clickRow={clickRow}
        />,
        summary_notactive: data.summary_notactive,
        summary_active: data.summary_active,
        summary_expired: data.summary_expired
      });
    }
    render() {
      return (
        <div className='background-input' style={{ padding: '30px' }}>

          {/* search */}
          <div className="row justify-content-between">
            <div className="col-12 col-md-3 mb-1">
              <button
                className="btn btn-warning text-light "
                onClick={(e) => {
                  e.preventDefault()
                  this.modelData.reset()
                  navigate(`/master_broadcast/input`)
                }}>
                Buat Broadcast
              </button>
            </div>
            <div className="col-12 col-md-4 mb-1">
              <div className="input-group">
                <select className="form-select"
                  value={this.state.searchcount}
                  onChange={(e) => { this.handleChange(e, 'searchcount') }}
                  style={{ maxWidth: '80px' }}
                >
                  <option value="10" >10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <input type="text" className="form-control" placeholder="Pencarian"
                  value={this.state.search}
                  onChange={(e) => { this.handleChange(e, 'search') }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.modelData.reset()
                      this.modelData.set('search', this.state.search)
                      this.previewData()
                    }
                  }}
                />
                <button className='btn btn-warning text-light'
                  onClick={(e) => {
                    e.preventDefault()
                    this.modelData.reset()
                    this.modelData.set('search', this.state.search)
                    this.previewData()
                  }}
                >
                  <span className="material-icons d-flex">
                    search
                  </span>
                </button>
              </div>
            </div>

          </div>
          {/* table content */}
          <div className="mt-3">
            {this.state.viewTable}
          </div>
        </div >
      )
    }
  }

  // inputing data  
  class ViewInputingData extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        desc: '',
        typesendnotif: 'none',
        datasetMember: [],

        mode: props.mode,
        iddata: props.iddata,
      };
      this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
      if (this.state.mode === 'edit') {
        fetch(urlweb + '/api_admin_v1/view_broadcast', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ iddata: this.state.iddata }),
        })
          .then(response => response.json())
          .then(data => {
            if (data.status === 'true') {
              if (data.data.length > 0) {
                this.setState({
                  name: data.data[0].Title,
                  desc: data.data[0].Description,
                })
              } else {
                AlertDialog('', 'Data tidak ditemukan', (ctxdialog) => {
                  ctxdialog.hide();
                  navigate(`/master_broadcast`)
                });
              }
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    }
    handleChange(event, name) {
      this.setState({ [name]: event.target.value });
    }

    searchMember(e) {
      e.preventDefault();
      const initSearchMember = new ModalCreate()
      const modalSearchMember = <ModalSearchMember
        closeModal={() => {
          initSearchMember.remove()
        }}

        chooseData={(dataMember) => {
          this.memberAdd(dataMember)
        }}

      />
      initSearchMember.attach(modalSearchMember)
    }
    memberAdd(dataMember) {
      let datasettemp = this.state.datasetMember

      let flag = true
      for (let i = 0; i < datasettemp.length; i++) {
        if (datasettemp[i].ID === dataMember.ID) {
          flag = false
          break;
        }
      }

      if (flag) {
        datasettemp.push(dataMember)
        this.setState({
          datasetMember: datasettemp
        })
        setTimeout(() => {
          this.memberView()
        }, 100);
      } else {

      }
    }
    memberDelete(e, index) {
      e.preventDefault()
      let datasettemp = this.state.datasetMember

      datasettemp.splice(index, 1)
      this.setState({
        datasetMember: datasettemp
      })
      setTimeout(() => {
        this.memberView()
      }, 100);
    }
    memberView() {
      const dataMember = this.state.datasetMember
      const viewTemp = []
      for (let i = 0; i < dataMember.length; i++) {
        viewTemp.push(<Fragment key={i}>
          <button type="button"
            className="list-group-item list-group-item-action"
            onClick={(e) => { this.memberDelete(e, i) }}>
            {dataMember[i].CodeMember + ' - ' + dataMember[i].NameMember}
          </button>
        </Fragment>)
      }
      this.setState({ viewTableMember: viewTemp })
    }

    saveData(e) {
      e.preventDefault();
      ConfirmDialog('', 'Anda yakin ingin menyimpan data ini ?',
        (ctxdialog) => {
          const param = {
            token: GetCokkiee('token'),
            iddata: this.state.iddata,
            name: this.state.name,
            desc: this.state.desc,
            img: "",
            typesendnotif: this.state.typesendnotif,
            datasetmember: this.state.datasetMember,
          }
          let urlinput = this.state.mode === 'edit' ? '' : '/api_admin_v1/insert_broadcast';
          RequestHttp(urlinput, param,
            function (data) {
              ctxdialog.hide();
              setTimeout(() => {
                if (data.status === 'true') {
                  Toast(data.message, '');
                  navigate(`/master_broadcast`)
                } else Toast(data.message, '');
              }, 100);
            }, function (error) {
              console.log(error);
            }
          );
        }, (ctxdialog) => { // action no
          ctxdialog.hide();
        }
      );
    }
    render() {
      return (
        <div className='background-input' style={{ padding: '30px' }}>
          <h4 style={{ fontWeight: 'bold' }}>{(this.state.mode === 'add') ? 'Tambah' : 'Edit'} Broadcast</h4>
          <div className="card">
            <div className="card-body">

              <form onSubmit={(e) => { this.saveData(e) }}>

                {/* Form Inputing */}
                <div className="row">
                  <div className='col-12'>
                    <div>Judul Broadcast</div>
                    <div className="mt-1 mb-2">
                      <input type="text" className="form-control" placeholder="Masukan Judul Broadcast" value={this.state.name} onChange={(e) => { this.handleChange(e, 'name') }} required />
                    </div>
                  </div>

                  <div className='col-12'>
                    <div>Deskripsi</div>
                    <div className="mt-1 mb-2">
                      <textarea className="form-control" placeholder="Masukan Deskripsi" rows="5" value={this.state.desc} onChange={(e) => { this.handleChange(e, 'desc') }} required />
                    </div>
                  </div>

                  <div className='col-12'>
                    <div>Tipe Pemberitahuan</div>
                    <div className="mt-1 mb-2">
                      <select className="form-select" value={this.state.typesendnotif} onChange={(e) => { this.handleChange(e, 'typesendnotif') }} >
                        <option value="all" >Semua Pengguna</option>
                        <option value="specific" >Pengguna Tertentu</option>
                        <option value="none" >Tanpa Notif</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12">
                    <div style={(this.state.typesendnotif === 'specific') ? { display: 'block' } : { display: 'none' }}>
                      <button type="button" className="btn btn-warning mb-2" onClick={(e) => { this.searchMember(e) }}>Tambah Member</button>
                      <div className="card " >
                        <div className="card-header">Member Yang di Broadcast</div>
                        <div className="card-body">
                          {this.state.viewTableMember}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-4 d-flex">
                    {(this.state.mode === 'add') &&
                      <button type="submit" className="btn btn-warning text-light me-1" >Simpan</button>
                    }
                    <button type="button" className={(this.state.mode === 'add') ? "btn btn-outline-warning" : "btn btn-warning text-light"}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/master_broadcast`)
                      }}
                    >Batalkan</button>
                  </div>

                </div>
                {/* Form Inputing */}

              </form>
            </div>
          </div>
        </div >
      )
    }
  }
  function Inputing() {
    let { id } = useParams();
    return <ViewInputingData mode="edit" iddata={id} />
  }

  return (
    <Routes>
      <Route index element={<ViewBrowse />} />
      <Route path={`/input`} element={<ViewInputingData mode="add" iddata="0" />} />
      <Route path={`/input/:id`} element={<Inputing />} />
    </Routes>
  )
}

export default App;