import './App.css';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate
} from 'react-router-dom';

import { GetCokkiee, ClearCokkiee, SetCokkiee, RequestHttp, ModeViewApp } from './utils/Main.js';
import { ConfirmDialog, Toast } from './component/Component.js';
// import imgLogo from '../src/assets/logo.png';

import PageLogin from '../src/pages/Login.js';
import MenuDashboard from '../src/pages/MenuDashboard'
import MenuPromoCategory from '../src/pages/MenuPromoCategory'
import MenuPromo from '../src/pages/MenuPromo'
import MenuProductCategory from '../src/pages/MenuProductCategory'
import MenuProductHighlist from '../src/pages/MenuProductHighlist'
import MenuProduct from '../src/pages/MenuProduct'
import MenuBroadcast from '../src/pages/MenuBroadcast'
import MenuBanner from '../src/pages/MenuBanner'
import MenuMember from '../src/pages/MenuMember'
import MenuAdmin from '../src/pages/MenuAdmin'

import MenuTransaction from '../src/pages/MenuTransaction'
import MenuTransactionCheck from '../src/pages/MenuTransactionCheck'
// import MenuTransactionOnline from '../src/pages/MenuTransactionOnline'

import MenuChangePassword from '../src/pages/MenuChangePassword'


class CheckPrivilage extends React.Component { // check privilage form and render form
  constructor(props) {
    super(props)
    this.state = {
      viewData: '',
      loadmenu: props.loadmenu,
      form: props.form,
    }
  }
  componentDidMount() {
    this.httpCheckPrivilage()

  }
  componentDidUpdate(prevProps) {
    if (prevProps.form !== this.props.form) {
      this.setState({
        form: this.props.form,
        loadmenu: this.props.loadmenu,
      })
      this.httpCheckPrivilage()
    }
  }
  httpCheckPrivilage() {
    const ctx = this
    RequestHttp('/api_admin_v1/check_privilage', {
      token: GetCokkiee('token'),
      loadform: this.state.loadmenu
    }, (data) => {
      if (data.status === 'true') {
        ctx.changeView()
      } else if (data.status === 'false') {
        if (data.message === 'Invalid Session') {
          window.alert('Sesi telah habis. Mohon login kembali');
          SetCokkiee("username", '');
          SetCokkiee("token", '');
          window.location.reload();
        } else {
          Toast(data.message, '')
        }
      } else {
        Toast('Terjadi Kesalahan', '')
      }
    }, (error) => {
      console.error('Error:', error);
    });
  }
  changeView() {
    // change style when click menu item
    const element = document.querySelectorAll(".menu-item-sidebar");
    for (let i = 0; i < element.length; i++) {
      element[i].classList.remove("active");
    }

    const elementcheck = document.getElementById(`iditem_${this.state.loadmenu}`)
    if (!elementcheck) {
      setTimeout(() => {
        document.getElementById(`iditem_${this.state.loadmenu}`).classList.add('active');
      }, 1000);
    } else {
      elementcheck.classList.add('active');
    }

    this.setState({ viewData: this.state.form })
  }
  render() {
    return this.state.viewData
  }
}
function MenuItem(props) { // render menu item
  let navigate = useNavigate();

  return <div id={`iditem_${props.data.LoadContent}`}
    className={'d-flex align-items-center menu-item-sidebar'}
    style={{ paddingTop: '6px', paddingBottom: '6px', paddingLeft: props.paddingLeft || '0px', cursor: 'pointer', borderRadius: '5px', width: '100%' }}
    data-loadmenu={props.data.LoadContent}
    onClick={(e) => {
      if (props.onClick !== undefined) {
        props.onClick(e)
      } else {
        e.preventDefault();
        if (e.target.dataset.loadmenu !== undefined) {
          navigate(e.target.dataset.loadmenu);
        }
      }
    }}
  >
    {(props.data.Img !== undefined)
      && <span className='material-icons ms-2 me-2'>{props.data.Img}</span>
    }
    {props.data.NameMenu}
  </div>
}
function MenuItemSub(props) { // render submenu item
  if (props.datamenu.ItemMenu.length > 0) {

    let viewMenuChild = []
    for (const i2 in props.datamenu.ItemMenu) {
      viewMenuChild.push(<MenuItem key={i2} data={props.datamenu.ItemMenu[i2]} paddingLeft={'41px'} />)
      // props.datamenu.ItemMenu[i2].ItemMenu // loop child menu
    }

    const idCollapse = `idsub${props.datamenu.NameMenu}`
    return <div>
      <div className='d-flex align-items-center menu-item-sidebar justify-content-between' type="button" data-bs-toggle="collapse" data-bs-target={`#${idCollapse}`} style={{ borderRadius: '5px' }}>
        <MenuItem data={props.datamenu} />
        <span className='material-icons me-1' style={{ color: 'white' }}>arrow_drop_down</span>
      </div>
      <div id={idCollapse} className="collapse show">
        {viewMenuChild}
      </div>
    </div>
  } else {
    return <MenuItem data={props.datamenu} />
  }
}
class AppMain extends React.Component { // render main content and side menu
  constructor(props) {
    super(props)
    let modeViewApp = ModeViewApp()
    this.state = {
      viewMenuList: '',

      // sidebar 
      buttonSidebarActive: (modeViewApp === 'desktop-view') ? true : false,
      flagSidebarShow: (modeViewApp === 'desktop-view') ? true : false,
      wideSideBar: (modeViewApp === 'desktop-view') ? '280px' : '0px',
      widthSideBarContentMove: (modeViewApp === 'desktop-view') ? '280px' : '0px',
      widthSideBarOffset: '0px',
    }
  }
  componentDidMount() {
    this.httpGetListMenu()

  }
  httpGetListMenu() {

    const renderSideMenu = (datamenu) => {


      const getMarginIndentation = (indentation) => {
        let paddingLeft = '0px'
        if (indentation === 1) {
          paddingLeft = '45px'
        } else if (indentation === 2) {
          paddingLeft = '65px'
        } else if (indentation === 3) {
          paddingLeft = '80px'
        } else if (indentation === 4) {
          paddingLeft = '100px'
        }
        return paddingLeft
      }

      const viewTemp = []
      const dataMenu = []

      const ItemMenu = (props) => {

        // function get child
        const getViewChild = (item, indentation) => {
          const viewTempChild = []
          const marginLeft = getMarginIndentation(indentation)
          for (const i in item) {

            // insert menu to data search menu
            dataMenu.push({
              NameMenu: item[i].NameMenu,
              LoadContent: item[i].LoadContent
            })

            const itemChild = getViewChild(item[i].ItemMenu, (indentation + 1)) // get child

            const idCollapse = `idcollapse_${item[i].NameMenu}`
            viewTempChild.push(<div key={i} >
              {(item[i].ItemMenu.length == 0)
                ? <div className='d-flex align-items-center menu-item-sidebar ps-3 pe-3 pt-2 pb-2' style={{ whiteSpace: 'nowrap', cursor: 'default', width: '100%' }}
                  id={`iditem_${item[i].LoadContent}`}
                  data-loadmenu={item[i].LoadContent}
                  onClick={(e) => {
                    e.preventDefault();
                    if (e.target.dataset.loadmenu !== undefined) {
                      navigate(e.target.dataset.loadmenu);
                    }
                  }}
                >
                  <div style={{ marginLeft: marginLeft }} data-loadmenu={item[i].LoadContent}>
                    {item[i].NameMenu}
                  </div>
                </div>
                : <div className='d-flex align-items-center menu-item-sidebar ps-3 pe-3 pt-2 pb-2'
                  id={`iditem_${item[i].LoadContent}`}
                  type="button" data-bs-toggle="collapse" data-bs-target={`#${idCollapse}`}
                >
                  <div className='sidebar-item-invisible ' style={{ whiteSpace: 'nowrap', width: '100%' }}>
                    <div className='d-flex justify-content-between'>
                      <div style={{ marginLeft: marginLeft }} data-loadmenu={item[i].LoadContent}>
                        {item[i].NameMenu}
                      </div>
                      <span className='material-icons me-1' style={{ color: 'white' }}>arrow_drop_down</span>
                    </div>
                  </div>
                </div>
              }

              {(item[i].ItemMenu.length > 0) && <div id={idCollapse} className='collapse sidebar-item-invisible'>{itemChild}</div>}
            </div >)
          }
          return viewTempChild
        }

        const dataItem = props.item
        let navigate = useNavigate()

        // insert menu to data search menu
        dataMenu.push({
          NameMenu: dataItem.NameMenu,
          LoadContent: dataItem.LoadContent
        })

        const itemChild = getViewChild(dataItem.ItemMenu, 1) // get child

        const idCollapse = `idcollapse_${dataItem.NameMenu}`
        return <div>
          {(dataItem.ItemMenu.length == 0)
            ? <div className='d-flex align-items-center menu-item-sidebar ps-3 pe-3 pt-2 pb-2'
              id={`iditem_${dataItem.LoadContent}`}
              data-loadmenu={dataItem.LoadContent}
              onClick={(e) => {
                e.preventDefault();
                if (e.target.dataset.loadmenu == 'logout') {
                  this.logoutAccount()
                } else if (e.target.dataset.loadmenu !== undefined) {
                  navigate(e.target.dataset.loadmenu);
                }
              }}
            >
              {(dataItem.Img !== undefined) && <span className='material-icons ms-2 me-2' style={{ fontSize: '30px' }}>{dataItem.Img}</span>}
              <div className='sidebar-item-invisible ' style={{ whiteSpace: 'nowrap', width: '100%' }}>
                <div className='d-flex justify-content-between' data-loadmenu={dataItem.LoadContent}>
                  {dataItem.NameMenu}
                </div>
              </div>
            </div>
            : <div className='d-flex align-items-center menu-item-sidebar ps-3 pe-3 pt-2 pb-2'
              id={`iditem_${dataItem.LoadContent}`}
              type="button" data-bs-toggle="collapse" data-bs-target={`#${idCollapse}`}
            >
              {(dataItem.Img !== undefined) && <span className='material-icons ms-2 me-2' style={{ fontSize: '30px' }}>{dataItem.Img}</span>}
              <div className='sidebar-item-invisible ' style={{ whiteSpace: 'nowrap', width: '100%' }}>
                <div className='d-flex justify-content-between'>
                  {dataItem.NameMenu}
                  <span className='material-icons me-1' style={{ color: 'white' }}>arrow_drop_down</span>
                </div>
              </div>
            </div>}
          {(dataItem.ItemMenu.length > 0) && <div id={idCollapse} className='collapse sidebar-item-invisible bg-sidebar-block'>{itemChild}</div>}
        </div >
      }

      for (const i in datamenu) {
        viewTemp.push(<ItemMenu key={i} item={datamenu[i]} />)
      }
      const itemMenuLogout = {
        NameMenu: 'Logout',
        LoadContent: 'logout',
        Img: 'exit_to_app',
        ItemMenu: []
      }
      viewTemp.push(<ItemMenu key={viewTemp.length} item={itemMenuLogout} />)

      window.dataMenu = dataMenu
      this.setState({
        viewMenuList: viewTemp
      })
    }

    RequestHttp('/api_admin_v1/menu_privilage', {
      token: GetCokkiee('token'),
    }, (data) => {
      if (data.status === 'true') {
        renderSideMenu(data.datamenu)
      } else if (data.status === 'false') {
        Toast(data.message, '')
      } else {
        Toast('Terjadi Kesalahan', '')
      }
    }, (error) => {
      console.error('Error:', error);
    });

  }
  onChangeSideBar(sender) {
    let modeViewApp = ModeViewApp()

    let buttonSidebarActive = this.state.buttonSidebarActive
    if (sender == 'button') {
      if (modeViewApp === 'desktop-view') {
        buttonSidebarActive = !this.state.buttonSidebarActive
        this.setState({
          buttonSidebarActive: !this.state.buttonSidebarActive
        })
      } else {

      }
    } else if (sender === 'mouse_enter') {
      // collapse sidebar
      if (!this.state.flagSidebarShow) {
        const listSubMenu = document.querySelectorAll('.collapse.show')
        listSubMenu.forEach(item => {
          item.classList.remove('show')
        })
      }
    }

    const SelectorSidebarItemHide = (isHide) => {
      let itemdiv = document.querySelectorAll('.sidebar-item-invisible')
      itemdiv.forEach(item => {
        if (isHide) {
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      });
    }

    let flagSidebarShow = this.state.flagSidebarShow
    if (!buttonSidebarActive) {
      if (sender == 'mouse_enter') {
        flagSidebarShow = false // set false because to be true
      } else if (sender == 'mouse_leave') {
        flagSidebarShow = true // set true because to be false
      }
    }

    SelectorSidebarItemHide(flagSidebarShow)

    if (modeViewApp == 'desktop-view') {
      if (flagSidebarShow) {
        this.setState({
          flagSidebarShow: false,
          wideSideBar: '80px',
          widthSideBarContentMove: '80px',
          widthSideBarOffset: '0px'
        })
      } else {
        this.setState({
          flagSidebarShow: true,
          wideSideBar: '280px',
          widthSideBarContentMove: (buttonSidebarActive) ? '280px' : '80px',
          widthSideBarOffset: '0px'
        })
      }
    } else {
      if (flagSidebarShow) {
        this.setState({
          flagSidebarShow: false,
          wideSideBar: '0px',
          widthSideBarContentMove: '0px',
          widthSideBarOffset: '0px'
        })
      } else {
        this.setState({
          flagSidebarShow: true,
          wideSideBar: '280px',
          widthSideBarContentMove: (buttonSidebarActive) ? '280px' : '80px',
          widthSideBarOffset: '0px'
        })
      }
    }
  }
  logoutAccount() {
    ConfirmDialog('', 'Logout Akun ?',
      (ctxdialog) => {
        ctxdialog.hide();
        setTimeout(() => {
          ClearCokkiee();
          window.location.reload();
        }, 100);
      }, (ctxdialog) => { // action no
        ctxdialog.hide();
      }
    );
  }
  render() {
    return <div>
      <BrowserRouter>
        {/* sidebar */}
        <div id="tagSideBar"
          style={{ width: this.state.wideSideBar, marginLeft: this.state.widthSideBarOffset, height: '100vh', overflow: 'auto' }}
          onMouseDown={() => { if (!this.state.buttonSidebarActive) { this.onChangeSideBar('mouse_enter') } }}
          onMouseLeave={() => { if (!this.state.buttonSidebarActive) { this.onChangeSideBar('mouse_leave') } }}
        >
          <div style={{ paddingTop: '20px' }}>
            {/* {((this.state.flagSidebarShow) && (this.state.viewMenuList !== '')) &&
              <div className='ps-3 pe-3'>
                <button className='btn btn-outline-light' style={{ width: '100%' }} onClick={() => this.searchMenu()}>Cari Menu</button>
              </div>
            } */}
            {this.state.viewMenuList}
          </div>
        </div>

        {/* content */}
        <div id='tagBodyContent' style={{ paddingLeft: this.state.widthSideBarContentMove, height: '100vh', overflowY: 'auto' }} >
          <nav className="navbar navbar-light bg-light" style={{ backgroundColor: 'white' }}>
            <div className="container-fluid">
              <button className="btn btn-light me-3" type="button" onClick={(e) => this.onChangeSideBar('button')}>
                <span className='material-icons d-flex'>
                  menu
                </span>
              </button>
            </div>
          </nav>
          <div>
            <Routes>

              <Route index
                element={<div className="d-flex justify-content-center align-items-center" style={{ width: '100%', padding: '20px' }}>
                  <div className="alert alert-primary" role="alert" style={{ width: '100%' }}>
                    Selamat datang di aplikasi ADMIN, <br /> Klik pada menu icon navigasi membuka menu
                  </div>
                </div>}
              />

              <Route path='/master_dashboard/*' element={<CheckPrivilage loadmenu='/master_dashboard' form={<MenuDashboard />} />} />
              <Route path='/master_promo_category/*' element={<CheckPrivilage loadmenu='/master_promo_category' form={<MenuPromoCategory />} />} />
              <Route path='/master_promo_item/*' element={<CheckPrivilage loadmenu='/master_promo_item' form={<MenuPromo />} />} />
              <Route path='/master_product_category/*' element={<CheckPrivilage loadmenu='/master_product_category' form={<MenuProductCategory />} />} />
              <Route path='/master_product_highlist/*' element={<CheckPrivilage loadmenu='/master_product_highlist' form={<MenuProductHighlist />} />} />
              <Route path='/master_product/*' element={<CheckPrivilage loadmenu='/master_product' form={<MenuProduct />} />} />
              <Route path='/master_broadcast/*' element={<CheckPrivilage loadmenu='/master_broadcast' form={<MenuBroadcast />} />} />
              <Route path='/master_banner/*' element={<CheckPrivilage loadmenu='/master_banner' form={<MenuBanner />} />} />
              <Route path='/master_member/*' element={<CheckPrivilage loadmenu='/master_member' form={<MenuMember />} />} />
              <Route path='/master_admin/*' element={<CheckPrivilage loadmenu='/master_admin' form={<MenuAdmin />} />} />

              <Route path='/transaction_order/*' element={<CheckPrivilage loadmenu='/transaction_order' form={<MenuTransaction />} />} />
              <Route path='/transaction_order_check/*' element={<CheckPrivilage loadmenu='/transaction_order_check' form={<MenuTransactionCheck />} />} />

              <Route path='/change_password/*' element={<CheckPrivilage loadmenu='/change_password' form={<MenuChangePassword />} />} />

            </Routes>
          </div>
        </div>

      </BrowserRouter>
    </div>
  }
}
class CheckSession extends React.Component { // check session exists
  constructor(props) {
    super(props)
    this.state = {
      viewData: 'loading',
    }
  }
  componentDidMount() {
    this.checkSession();
  }
  checkSession() {
    const token = GetCokkiee('token');
    if (token === '') {
      this.setState({ viewData: <PageLogin /> })
    } else {
      const ctx = this;
      RequestHttp('/api_admin_v1/checkaccount', {
        token: token
      }, (data) => {
        if (data.status === 'true') {
          ctx.setState({ viewData: <AppMain /> })
        } else if (data.status === 'false') {
          Toast(data.message, () => {
            ClearCokkiee();
            ctx.setState({ viewData: <PageLogin /> })
          });
        } else {
          Toast('Terjadi Kesalahan', '');
        }
      }, (error) => {
        console.log(error);
        Toast('Terjadi Kesalahan', '');
      })
    }
  }
  render() {
    return this.state.viewData
  }
}

export default CheckSession;

