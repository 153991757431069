import React, { Fragment } from 'react';
import {
  Routes,
  Route,
  useParams,
  useNavigate,
} from 'react-router-dom';

import { ipserver, GetCokkiee, RequestHttp } from '../utils/Main.js';
import { ConfirmDialog, AlertDialog, Toast, TableData, EntityPagging, ModalCreate } from '../component/Component.js';
import imgnotfound from '../assets/notfound.png';
const urlweb = ipserver;


const nameEntityPagging = 'table_master_member'
function App() {
  let navigate = useNavigate();

  class ModalViewMember extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        nameModal: 'modalViewMember',
        iddata: props.idmember,

        codemember: '',
        namemember: '',
        email: '',
        telp: '',
        birth: '',
        address: '',
        img: '',
        status: '',

        setting_isActiveCart: false,
        setting_isActiveShowPriceProduct: false,

        closeModal: props.closeModal
      }
    }
    componentDidMount() {
      document.getElementById(this.state.nameModal + '_show').click();
      this.httpGetDataMember()
    }
    httpGetDataMember() {
      const ctx = this
      RequestHttp('/api_admin_v1/view_member', {
        iddata: this.state.iddata
      }, (data) => {
        console.log(data);
        if (data.status === 'true') {
          ctx.setState({
            codemember: data.data.CodeMember,
            namemember: data.data.NameMember,
            email: data.data.Email,
            telp: data.data.Telp,
            birth: data.data.Birth,
            address: data.data.Address,
            img: (data.data.Img !== '') ? data.data.Img : '_',
            status: data.data.StatusMember,
            setting_isActiveCart: (data.datasetting.isActiveCart == 'true') ? true : false,
            setting_isActiveShowPriceProduct: (data.datasetting.isActiveShowPriceProduct == 'true') ? true : false,
          })
        } else if (data.status === 'false') {
          Toast(data.message, '');
        } else {
          Toast('Terjadi Kesalahan', '');
        }
      }, (error) => {
        console.log(error);
      })
    }
    httpChangeStatusMember(e, mode) {
      e.preventDefault()
      if (mode === 'Aktif') {
        const r = window.confirm('Anda yakin ingin mengaktifkan member ini ?')
        if (r) {
          const ctx = this
          RequestHttp('/api_admin_v1/update_member_status', {
            token: GetCokkiee('token'),
            iddata: this.state.iddata,
            islock: 0
          }, (data) => {
            console.log(data);
            if (data.status === 'true') {
              window.alert(data.message)
              ctx.saveModal()
            } else if (data.status === 'false') {
              Toast(data.message, '');
            } else {
              Toast('Terjadi Kesalahan', '');
            }
          }, (error) => {
            console.log(error);
          })
        }
      } else if (mode === 'Nonaktif') {
        const r = window.confirm('Anda yakin ingin menonaktifkan member ini ?')
        if (r) {
          const ctx = this
          RequestHttp('/api_admin_v1/update_member_status', {
            token: GetCokkiee('token'),
            iddata: this.state.iddata,
            islock: 1
          }, (data) => {
            console.log(data);
            if (data.status === 'true') {
              window.alert(data.message)
              ctx.saveModal()
            } else if (data.status === 'false') {
              Toast(data.message, '');
            } else {
              Toast('Terjadi Kesalahan', '');
            }
          }, (error) => {
            console.log(error);
          })
        }
      }
    }
    httpSaveSettingMember(e) {
      e.preventDefault()
      const r = window.confirm('Simpan konfigurasi member ?')
      if (r) {
        const ctx = this
        RequestHttp('/api_admin_v1/update_member_setting', {
          token: GetCokkiee('token'),
          iddata: this.state.iddata,
          isActiveCart: (this.state.setting_isActiveCart) ? 1 : 0,
          isActiveShowPriceProduct: (this.state.setting_isActiveShowPriceProduct) ? 1 : 0,
        }, (data) => {
          console.log(data);
          if (data.status === 'true') {
            window.alert(data.message)
            ctx.saveModal()
          } else if (data.status === 'false') {
            Toast(data.message, '');
          } else {
            Toast('Terjadi Kesalahan', '');
          }
        }, (error) => {
          console.log(error);
        })
      }
    }
    saveModal() {
      document.getElementById(this.state.nameModal + '_hide').click();
      this.props.changeData()
      this.state.closeModal()
    }
    closeModal(e) {
      e.preventDefault()
      this.state.closeModal()
    }
    render() {
      return <Fragment>

        <button id={this.state.nameModal + '_show'} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${this.state.nameModal}`} style={{ display: 'none' }}>
          Launch demo modal
        </button>

        <div className="modal" id={this.state.nameModal} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" >Data Member</h5>
                <button id={this.state.nameModal + '_hide'} type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) => { this.closeModal(e) }}></button>
              </div>
              <div className="modal-body">

                <div className='row'>
                  <div className='col-4'>
                    <div className='mb-2'>
                      <img className='img-thumbnail' src={this.state.img} style={{ width: "150px", height: '150px', objectFit: 'contain' }} onError={(e) => { e.target.src = imgnotfound; }}></img>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='mb-2'>
                      <label>Member ID</label>
                      <input className='form-control' value={this.state.codemember} disabled={true}></input>
                    </div>

                    <div className='mb-2'>
                      <label>Nama Member</label>
                      <input className='form-control' value={this.state.namemember} disabled={true}></input>
                    </div>
                  </div>
                </div>


                <div className='mb-2'>
                  <label>Email</label>
                  <input className='form-control' value={this.state.email} disabled={true}></input>
                </div>

                <div className='row'>

                  <div className='col-md-6 mb-2'>
                    <label>Telepon</label>
                    <input className='form-control' value={this.state.telp} disabled={true}></input>
                  </div>

                  <div className='col-md-6 mb-2'>
                    <label>Tanggal Lahir</label>
                    <input className='form-control' value={this.state.birth} disabled={true}></input>
                  </div>

                </div>


                <div className='mb-2'>
                  <label>Alamat</label>
                  <textarea className='form-control' rows="5" value={this.state.address} disabled={true}>
                  </textarea>
                </div>

                <div className='mb-2'>

                  <div className="form-check">
                    <input className="form-check-input" name='cb__isActiveCart' type="checkbox"
                      checked={this.state.setting_isActiveCart}
                      onChange={(e) => { }}
                      onClick={(e) => {
                        this.setState({ setting_isActiveCart: e.target.checked })
                      }}
                    />
                    <label className="form-check-label" >Fitur Pemesanan</label>
                  </div>

                  <div className="form-check">
                    <input className="form-check-input" name='cb__isActiveShowPriceProduct' type="checkbox"
                      checked={this.state.setting_isActiveShowPriceProduct}
                      onChange={(e) => { }}
                      onClick={(e) => {
                        this.setState({ setting_isActiveShowPriceProduct: e.target.checked })
                      }}
                    />
                    <label className="form-check-label" >Fitur Informasi Harga</label>
                  </div>

                </div>

                <div className='d-flex justify-content-between'>
                  <button className='btn btn-primary me-2' onClick={(e) => this.httpSaveSettingMember(e)}>Simpan</button>
                  {(this.state.status === 'Nonaktif') && <button className='btn btn-secondary' onClick={(e) => this.httpChangeStatusMember(e, 'Aktif')}>Aktifkan Member</button>}
                  {(this.state.status === 'Aktif') && <button className='btn btn-danger' onClick={(e) => this.httpChangeStatusMember(e, 'Nonaktif')}>Nonaktifkan Member</button>}
                </div>

              </div>
            </div>
          </div>
        </div>
      </Fragment >
    }
  }

  // browse data 
  class ViewBrowse extends React.Component {
    constructor(props) {
      super(props);
      this.modelData = new EntityPagging(nameEntityPagging)
      this.state = {
        viewTable: 'loading...',
        search: this.modelData.get('search') || '',
      };
    }
    componentDidMount() {
      this.previewData();
    }
    handleChange(e, name) {
      this.setState({ [name]: e.target.value });
      if (name === 'searchcount') {
        this.modelData.set('count', e.target.value);
        this.previewData()
      }
    }
    deleteData(e, iddata) {
      e.preventDefault();
      const r = window.confirm('Hapus ?');
      if (r) {
        RequestHttp('/api_admin_v1/delete_admin', {
          iddata: iddata
        }, (data) => {
          if (data.status === 'true') {
            window.location.reload();
          } else if (data.status === 'false') {
            Toast(data.message, '')
          } else {
            Toast('Terjadi Kesalahan', '')
          }
        }, (error) => {
          console.log(error);
        });
      }
    }
    previewData() {
      const ctx = this
      RequestHttp('/api_admin_v1/list_member', {
        search: this.modelData.get('search'),
        searchcount: this.modelData.get('count'),
        index: this.modelData.get('index'),
        sortcolumn: this.modelData.get('sortcoumn'),
        sortorder: this.modelData.get('sortorder'),
      }, (data) => {
        if (data.status === 'true') {
          ctx.tableContent(data)
        } else if (data.status === 'false') {
          Toast(data.message, '')
        } else {
          Toast('Terjadi Kesalahan', '')
        }
      }, (error) => {
        console.log(error);
      });
    }
    tableContent(data) {
      const ctx = this

      const clickRow = function (e, item) {
        e.preventDefault();
        ctx.viewMember(e, item.ID)
        // navigate(`/master_member/view/${item.ID}`)
      }

      const tdAction = function (itemdata) {
        return <button className="btn btn-outline-light btn-border-none"
          onClick={(e) => { ctx.deleteData(e, itemdata.ID) }}>
          <span className="material-icons d-flex" style={{ color: 'grey' }}>
            delete
          </span>
        </button>
      }

      const tdStatus = function (itemdata) {
        return <div className={(itemdata.Status === 'Aktif') ? 'bg-success text-light ps-3 pe-3 pt-1 pb-1' : 'bg-danger text-light ps-3 pe-3 pt-1 pb-1'} style={{ borderRadius: '5px', textAlign: 'center' }}>
          {itemdata.Status}
        </div>
      }

      const tableData = {
        header: [
          // { caption: 'Actions', sort: '' },
          { caption: 'Member ID', sort: '', css: 'td-word-wrap' },
          { caption: 'Nama', sort: '', css: 'td-word-wrap' },
          { caption: 'Telepon', sort: '', css: 'td-word-wrap' },
          { caption: 'Tanggal Lahir', sort: '', css: 'td-word-wrap' },
          { caption: 'Alamat', sort: '' },
          { caption: 'Tanggal Pendaftaran', sort: '', css: 'td-word-wrap' },
          { caption: 'Status', sort: '', css: 'td-word-wrap' },
        ],
        body: [
          // { name: tdAction, type: 'view' },
          { name: 'CodeMember', click: true },
          { name: 'NameMember', click: true },
          { name: 'Telp', click: true },
          { name: 'Birth', click: true },
          { name: 'Address', click: true },
          { name: 'Address', click: true },
          { name: tdStatus, type: 'view', click: true },
        ],
      }

      this.setState({
        viewTable: <TableData
          ctx={this}
          nameModelData={nameEntityPagging}
          tableData={tableData}
          dataset={data.data}
          ctpages={data.ctpages}
          ctdata={data.ctdata}
          clickRow={clickRow}
        />
      });
    }
    viewMember(e, idmember) {
      e.preventDefault();
      const initModalViewMember = new ModalCreate()
      const modalViewMember = <ModalViewMember
        idmember={idmember}
        closeModal={() => {
          initModalViewMember.remove()
        }}

        changeData={() => {
          // this.memberAdd(dataMember)
          this.previewData()
        }}
      />
      initModalViewMember.attach(modalViewMember)
    }
    render() {
      return (
        <div style={{ padding: '20px' }}>

          {/* <div className="row mt-2">
            <div className="col-md-4 mb-3">
              <div className="card myhover" onClick={(e) => { this.filterData(e, 'Belum Aktif') }}>
                <div className="card-body text-secondary">
                  Total Member
                  <h2 className="text-dark" style={{ fontWeight: 'bolder' }}>{this.state.summary_notactive}</h2>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card myhover" onClick={(e) => { this.filterData(e, 'Aktif') }}>
                <div className="card-body text-secondary">
                  Member Baru
                  <h2 className="text-dark" style={{ fontWeight: 'bolder' }}>{this.state.summary_active}</h2>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card myhover" onClick={(e) => { this.filterData(e, 'Kadaluarsa') }}>
                <div className="card-body text-secondary">
                  Transaksi Member Terbaru
                  <h2 className="text-dark" style={{ fontWeight: 'bolder' }}>{this.state.summary_expired}</h2>
                </div>
              </div>
            </div>
          </div> */}

          {/* search */}
          <div className="row justify-content-between">
            <div className="col-12 col-md-3 mb-1">
            </div>
            <div className="col-12 col-md-4 mb-1">
              <div className="input-group">
                <select className="form-select"
                  value={this.state.searchcount}
                  onChange={(e) => { this.handleChange(e, 'searchcount') }}
                  style={{ maxWidth: '80px' }}
                >
                  <option value="10" >10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <input type="text" className="form-control" placeholder="Pencarian"
                  value={this.state.search}
                  onChange={(e) => { this.handleChange(e, 'search') }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.modelData.reset()
                      this.modelData.set('search', this.state.search)
                      this.previewData()
                    }
                  }}
                />
                <button className='btn btn-warning text-light'
                  onClick={(e) => {
                    e.preventDefault()
                    this.modelData.reset()
                    this.previewData()
                  }}
                >
                  <span className="material-icons d-flex">
                    search
                  </span>
                </button>
              </div>
            </div>
          </div>
          {/* table content */}
          <div className="mt-3">
            {this.state.viewTable}
          </div>

        </div >
      )
    }
  }

  return (
    <Routes>
      <Route index element={<ViewBrowse />} />
    </Routes>
  )
}


export default App;