import React, { Component } from 'react';

import { SetCokkiee, RequestHttp, ModeViewApp } from '../utils/Main.js';
import { Toast } from '../component/Component.js'
import imgBackground from '../assets/img-laptop.png';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };
    }
    handleChange(e, name) {
        this.setState({ [name]: e.target.value });
    }
    saveData(e) {
        e.preventDefault();
        if ((this.state.username === '') || (this.state.password === '')) {
            Toast('Mohon masukan data dengan lengkap', '')
        } else {
            RequestHttp('/api_admin_v1/login', {
                username: this.state.username,
                password: this.state.password
            }, (data) => {
                if (data.status === 'false') {
                    Toast(data.message, '')
                } else {
                    SetCokkiee("username", data.data.NameAdmin);
                    SetCokkiee("token", data.data.Token);
                    window.location.reload();
                }
            }, (error) => {
                console.error('Error:', error);
            });
        }
    }
    render() {
        const modeApp = ModeViewApp();
        return (
            <div style={{ position: 'fixed', top: '0px', bottom: '0px', height: '100%', width: '100%', backgroundColor: 'white' }}>
                <div className='row ms-0 me-0' style={{ height: '100%' }}>
                    {(modeApp != 'mobile-view') &&
                        <div className='col-md-8 ps-0 pb-0'>
                            <div className='d-flex justify-content-center align-items-center' style={{ height: '100%', width: '100%', backgroundColor: '#018730' }}>
                                <img src={imgBackground} style={{ width: '70%', height: 'auto', objectFit: 'contain' }} alt="" />
                            </div>
                        </div>
                    }
                    <div className='col-md-4 align-self-center'>
                        <form onSubmit={(e) => this.saveData(e)}>
                            <h3 className='mb-5' style={{ fontWeight: 'bold' }}>Selamat Datang Kembali</h3>
                            <div className='mb-1'><b>Username</b></div>
                            <input type='text' className='form-control mb-3' placeholder='Masukan Username' value={this.state.username} onChange={(e) => { this.handleChange(e, 'username') }} required={true} />
                            <div className='mb-1'><b>Password</b></div>
                            <input type='password' className='form-control mb-4' placeholder='Masukan Password' value={this.state.password} onChange={(e) => { this.handleChange(e, 'password') }} required={true} />
                            <button type='submit' className="btn btn-warning text-light" style={{ width: '100%' }} >Login</button>
                        </form>
                    </div>
                </div>
            </div >
        );
    }
}

export default Login;