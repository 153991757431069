import React, { Fragment } from 'react';
import {
  Route,
  Routes,
  useParams,
  useNavigate,
} from 'react-router-dom';

import { ipserver, GetCokkiee, DateInputNow, RequestHttp, CheckFileSize } from '../utils/Main.js';
import { ConfirmDialog, AlertDialog, Toast, TableData, ModalCreate, EntityPagging } from '../component/Component.js';
import { ModalSearchPromoForTemplate, ModalNotifMemberMultiple, ModalSearchCategoryPromo } from '../component/Modal.js';
import imgnotfound from '../assets/notfound.png';
const urlweb = ipserver;


const nameEntityPagging = 'table_master_promo'
function App() {
  let navigate = useNavigate();

  // browse data
  class ViewBrowse extends React.Component {
    constructor(props) {
      super(props);
      this.modelData = new EntityPagging(nameEntityPagging)
      this.modelData.setdefaultcolumn('a.ID')
      this.state = {
        viewTable: 'loading...',
        search: this.modelData.get('search') || '',
        summary_notactive: '-',
        summary_active: '-',
        summary_expired: '-',
        filterdata: 'Aktif'
      };
    }
    componentDidMount() {
      this.previewData();
    }
    handleChange(e, name) {
      this.setState({ [name]: e.target.value });
      if (name === 'searchcount') {
        this.modelData.set('count', e.target.value);
        this.previewData()
      }
    }
    changeSort(e, iddata, modesort) {
      e.preventDefault();
      const ctx = this;
      RequestHttp('/api_admin_v1/changesort_promo', {
        token: GetCokkiee('token'),
        iddata: iddata,
        modesort: modesort,
      }, (data) => {
        setTimeout(() => {
          if (data.status === 'true') {
            ctx.modelData.reset()
            ctx.previewData();
          } else if (data.status === 'false') {
            Toast(data.message, '');
          } else {
            Toast('Terjadi Kesalahan', '');
          }
        }, 100);
      }, (error) => {
        console.log(error);
      });
    }
    filterData(e, modefilter) {
      e.preventDefault();
      this.setState({ filterdata: modefilter })
      setTimeout(() => {
        this.previewData();
      }, 200);
    }
    notifMember(e, iddata) {
      e.preventDefault();
      const initModalNotifMemberMultiple = new ModalCreate()
      const modalNotifMemberMultiple = <ModalNotifMemberMultiple
        iddata={iddata}
        closeModal={() => {
          initModalNotifMemberMultiple.remove()
        }}

        chooseData={(dataPromo) => {

        }}
      />
      initModalNotifMemberMultiple.attach(modalNotifMemberMultiple)
    }
    deleteData(e, iddata) {
      e.preventDefault();
      const ctx = this;
      ConfirmDialog('', 'Anda yakin ingin menghapus data ini ?',
        (ctxdialog) => {
          const param = {
            token: GetCokkiee('token'),
            iddata: iddata
          }
          RequestHttp('/api_admin_v1/delete_promo', param,
            function (data) {
              ctxdialog.hide();
              setTimeout(() => {
                if (data.status === 'true') {
                  ctx.modelData.reset()
                  ctx.previewData();
                  Toast(data.message, (ctxalert) => { ctxalert.hide(); });
                } else if (data.status === 'false') {
                  Toast(data.message, '');
                } else {
                  Toast('Terjadi Kesalahan', '');
                }
              }, 100);
            }, function (error) {
              console.log(error);
            }
          );
        }, (ctxdialog) => { // action no
          ctxdialog.hide();
        }
      );
    }
    previewData() {
      const ctx = this;
      RequestHttp('/api_admin_v1/list_promo', {
        search: this.modelData.get('search'),
        searchcount: this.modelData.get('count'),
        index: this.modelData.get('index'),
        sortcolumn: this.modelData.get('sortcoumn'),
        sortorder: this.modelData.get('sortorder'),
        filterdata: this.state.filterdata
      }, (data) => {
        if (data.status === 'true') {
          ctx.tableContent(data)
        } else if (data.status === 'false') {
          Toast(data.message, '');
        } else {
          Toast('Terjadi kesalahan, silahkan coba lagi', '');
        }
      }, (error) => {
        console.log(error);
      });
    }
    tableContent(data) {
      const ctx = this;
      const tdImg = function (itemdata) {
        return <Fragment>
          <img className="img-thumbnail shadow-sm"
            src={`${urlweb}/api_module/file_promo/${itemdata.Img}`}
            style={{ minWidth: '100px', width: '100px', height: '60px', objectFit: 'cover' }}
            onError={(e) => { e.target.src = imgnotfound; }}
            alt="" />
        </Fragment>
      }
      const tdAction = function (itemdata) {
        return <Fragment>
          <button className="btn btn-outline-light btn-border-none"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/master_promo_item/input/${itemdata.ID}`)
            }}>
            <span className="material-icons d-flex" style={{ color: 'grey' }}>
              edit
            </span>
          </button>
          <button className="btn btn-outline-light btn-border-none"
            onClick={(e) => { ctx.deleteData(e, itemdata.ID) }}>
            <span className="material-icons d-flex" style={{ color: 'grey' }}>
              delete
            </span>
          </button>
        </Fragment>
      }
      // const tdPrice = function (itemdata) {
      //   return <Fragment>
      //     {itemdata.PriceBefore} / {itemdata.PriceAfter}
      //   </Fragment>
      // }
      const clickRow = function (e, item) {
        e.preventDefault();
        navigate(`/master_promo_item/input/${item.ID}`)
      }

      const tableData = {
        header: [
          { caption: 'Action' },
          { caption: 'Images', sort: '' },
          { caption: 'Kode Promo', sort: '', css: 'td-name-data' },
          { caption: 'Nama Promo', sort: '', css: 'td-name-data' },
          { caption: 'Nama Kategori', sort: '', css: 'td-word-wrap ' },
          { caption: 'Tanggal Aktif', sort: '', css: 'td-word-wrap ' },
          { caption: 'Tanggal Expired', sort: '', css: 'td-word-wrap ' },
          { caption: 'Status', sort: '', css: 'td-word-wrap ' },
        ],
        body: [
          { name: tdAction, type: 'view', css: 'td-actions' },
          { name: tdImg, type: 'view', click: true },
          { name: 'CodePromo', click: true },
          { name: 'NamePromo', click: true },
          { name: 'NameCategory', click: true },
          { name: 'StartDate', click: true },
          { name: 'ExpiredDate', click: true },
          { name: 'Status1', click: true },
        ],
      }

      this.setState({
        viewTable: <TableData
          ctx={this}
          nameModelData={nameEntityPagging}
          tableData={tableData}
          dataset={data.data}
          ctpages={data.ctpages}
          ctdata={data.ctdata}
          clickRow={clickRow}
        />,
        summary_notactive: data.summary_notactive,
        summary_active: data.summary_active,
        summary_expired: data.summary_expired
      });
    }
    render() {
      return (
        <div className='background-input' style={{ padding: '30px' }}>

          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card myhover">
                <div className="card-body text-secondary">
                  Nonaktif
                  <h2 className="text-dark" style={{ fontWeight: 'bolder' }} onClick={(e) => { this.filterData(e, 'Belum Aktif') }}>{this.state.summary_notactive}</h2>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card myhover">
                <div className="card-body text-secondary">
                  Aktif
                  <h2 className="text-dark" style={{ fontWeight: 'bolder' }} onClick={(e) => { this.filterData(e, 'Aktif') }}>{this.state.summary_active}</h2>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card myhover">
                <div className="card-body text-secondary">
                  Kadaluarsa
                  <h2 className="text-dark" style={{ fontWeight: 'bolder' }} onClick={(e) => { this.filterData(e, 'Kadaluarsa') }}>{this.state.summary_expired}</h2>
                </div>
              </div>
            </div>
          </div>

          {/* search */}
          <div className="row justify-content-between">
            <div className="col-12 col-md-3 mb-1">
              <button
                className="btn btn-warning text-light"
                onClick={(e) => {
                  e.preventDefault()
                  this.modelData.reset()
                  navigate('/master_promo_item/input')
                }}>
                Buat Promo
              </button>
            </div>
            <div className="col-12 col-md-4 mb-1">
              <div className="input-group">
                <select className="form-select"
                  value={this.state.searchcount}
                  onChange={(e) => { this.handleChange(e, 'searchcount') }}
                  style={{ maxWidth: '80px' }}
                >
                  <option value="10" >10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <input type="text" className="form-control" placeholder="Pencarian"
                  value={this.state.search}
                  onChange={(e) => { this.handleChange(e, 'search') }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.modelData.reset()
                      this.modelData.set('search', this.state.search)
                      this.previewData()
                    }
                  }}
                />
                <button className='btn btn-warning text-light'
                  onClick={(e) => {
                    e.preventDefault()
                    this.modelData.reset()
                    this.modelData.set('search', this.state.search)
                    this.previewData()
                  }}
                >
                  <span className="material-icons d-flex">
                    search
                  </span>
                </button>
              </div>
            </div>

          </div>
          {/* table content */}
          <div className="mt-3">
            {this.state.viewTable}
          </div>

        </div >
      )
    }
  }

  // input data
  class InputData extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        code: '',
        name: '',
        desc: '',
        idcategory: '',
        namecategory: '',
        img: '',
        pricebefore: '0',
        priceafter: '0',
        pricediff: '0',
        startdate: DateInputNow(),
        expireddate: DateInputNow(),
        isshowprice: "0",
        isshowexpired: "0",
        issendnotif: "0",

        mode: props.mode,
        iddata: props.iddata,
      };
      this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
      if (this.state.mode === 'edit') {
        const ctx = this;
        RequestHttp('/api_admin_v1/view_promo', {
          iddata: this.state.iddata
        }, (data) => {
          if (data.status === 'true') {
            if (data.data.length > 0) {
              this.setState({
                code: data.data[0].CodePromo,
                name: data.data[0].NamePromo,
                desc: data.data[0].Description,
                idcategory: data.data[0].IDCategory,
                namecategory: data.data[0].NameCategory,
                img: data.data[0].Img,
                pricebefore: data.data[0].PriceBefore,
                priceafter: data.data[0].PriceAfter,
                startdate: data.data[0].StartDate1,
                expireddate: data.data[0].ExpiredDate1,
                isshowprice: String(data.data[0].isShowPrice),
                isshowexpired: String(data.data[0].isShowExpired),
              })
              document.querySelector('#imgView').src = `${urlweb}/api_module/file_promo/${data.data[0].Img}`

              setTimeout(() => {
                ctx.calculatePrice()
              }, 200);
            } else {
              AlertDialog('', 'Data tidak ditemukan', (ctxdialog) => {
                ctxdialog.hide();
                navigate(`/master_promo_item`)
              });
            }
          } else if (data.status === 'false') {
            Toast(data.message, '')
          } else {
            Toast('Terjadi Kesalahan', '')
          }
        }, (error) => {
          console.log(error);
        });
      }
    }
    handleChange(event, name) {
      this.setState({ [name]: event.target.value });
      if ((name === 'priceafter') || (name === 'pricebefore')) {
        setTimeout(() => {
          this.calculatePrice()
        }, 200);
      }
    }

    searchCategory(e) {
      e.preventDefault();
      const initModal = new ModalCreate()
      initModal.attach(<ModalSearchCategoryPromo
        closeModal={() => {
          initModal.remove()
        }}

        chooseData={(dataCategory) => {
          this.setState({
            idcategory: dataCategory.ID,
            namecategory: dataCategory.NameCategory,
          })
        }}

      />)
    }
    calculatePrice() {
      let pricebefore = this.state.pricebefore
      let priceafter = this.state.priceafter
      if (pricebefore === '') pricebefore = 0
      if (priceafter === '') priceafter = 0
      const pricediff = parseFloat(pricebefore) - parseFloat(priceafter)
      this.setState({ pricediff: pricediff })
    }
    searchPromoTemplate(e) {
      e.preventDefault();
      const initModalSearchPromo = new ModalCreate()
      const modalSearchProduct = <ModalSearchPromoForTemplate
        closeModal={() => {
          initModalSearchPromo.remove()
        }}

        chooseData={(dataPromo) => {
          this.setState({
            // code: dataPromo.CodePromo,
            name: dataPromo.NamePromo,
            desc: dataPromo.Description,
            idcategory: dataPromo.IDCategory,
            img: dataPromo.Img,
            pricebefore: dataPromo.PriceBefore,
            priceafter: dataPromo.PriceAfter,
            startdate: dataPromo.StartDate,
            expireddate: dataPromo.ExpiredDate,
            isshowprice: String(dataPromo.isShowPrice),
            isshowexpired: String(dataPromo.isShowExpired),
          })

          document.querySelector('#imgView').src = `${urlweb}/api_module/file_promo/${dataPromo.Img}`

          setTimeout(() => {
            this.calculatePrice()
          }, 200);
        }}

      />
      initModalSearchPromo.attach(modalSearchProduct)
    }

    saveData(e) {
      e.preventDefault();
      var startdate = new Date(this.state.startdate);
      var expireddate = new Date(this.state.expireddate);
      if (startdate > expireddate) {
        Toast('Tanggal Aktif harus lebih kecil dari pada tanggal kadaluarsa', '');
      } else {
        ConfirmDialog('', 'Anda yakin ingin menyimpan data ini ?',
          (ctxdialog) => {
            let urlinput = '/api_admin_v1/insert_promo';
            if (this.state.mode === 'edit') urlinput = '/api_admin_v1/update_promo'
            const param = {
              token: GetCokkiee('token'),
              iddata: this.state.iddata,
              code: this.state.code,
              name: this.state.name,
              desc: this.state.desc,
              idcategory: this.state.idcategory,
              img: this.state.img,
              pricebefore: this.state.pricebefore,
              priceafter: this.state.priceafter,
              pricediff: this.state.pricediff,
              startdate: this.state.startdate,
              expireddate: this.state.expireddate,
              isshowprice: this.state.isshowprice,
              isshowexpired: this.state.isshowexpired,
              sendnotif: (this.state.issendnotif === '1') ? true : false,

            }
            RequestHttp(urlinput, param,
              function (data) {
                ctxdialog.hide();
                setTimeout(() => {
                  if (data.status === 'true') {
                    Toast(data.message, '');
                    navigate(`/master_promo_item`)
                  } else if (data.status === 'false') {
                    Toast(data.message, '');
                  } else {
                    Toast('Terjadi kesalahan pada server', '');
                  }
                }, 100);
              }, function (error) {
                console.log(error);
              }
            );
          }, (ctxdialog) => { // action no
            ctxdialog.hide();
          }
        );
      }
    }
    uploadImage(e) {
      e.preventDefault();
      const checkfile = CheckFileSize(document.querySelector('#edtInputImage'));
      if (checkfile !== 'true') {
        Toast(checkfile, '');
      } else {
        let input = document.querySelector('#edtInputImage');
        let dataImg = new FormData()
        dataImg.append('myfile', input.files[0])
        dataImg.append('modeupload', 'promo')

        fetch(`${urlweb}/api_module/upload`, {
          method: 'POST',
          body: dataImg // This is your file object
        })
          .then(response => response.json())
          .then(data => {
            if (data.status === 'true') {
              const img = data.link
              this.setState({ img: img })
              document.querySelector('#imgView').src = `${urlweb}/api_module/file_promo/${img}`
            } else {
              this.setState({ img: '' })
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    }
    render() {
      return (
        <div className='background-input' style={{ padding: '30px' }}>
          <h4 style={{ fontWeight: 'bold' }}>{(this.state.mode === 'add') ? 'Tambah' : 'Edit'} Promo</h4>
          <div className="card">
            <div className="card-body" style={{ padding: '50px' }}>
              {/* <div className="mb-2">
                <button className="btn btn-secondary" onClick={(e) => { this.searchPromoTemplate(e) }}>Copy Template</button>
              </div>
              <hr /> */}
              <form onSubmit={(e) => { this.saveData(e) }}>

                {/* Form Inputing */}
                <div className="row">

                  <div className='col-md-6'>
                    <div>Kode Promo</div>
                    <div className="mt-1 mb-2">
                      <input type="text" className="form-control" placeholder="Masukan Kode Promo" value={this.state.code} onChange={(e) => { this.handleChange(e, 'code') }} required />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div>Nama Promo</div>
                    <div className="mt-1 mb-2">
                      <input type="text" className="form-control" placeholder="Masukan Nama Promo" value={this.state.name} onChange={(e) => { this.handleChange(e, 'name') }} required />
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div>Tanggal Mulai</div>
                    <div className="mt-1 mb-2">
                      <input type="date" className="form-control" placeholder="Tanggal Mulai" value={this.state.startdate} onChange={(e) => { this.handleChange(e, 'startdate') }} required />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div>Tanggal Berakhir</div>
                    <div className="mt-1 mb-2">
                      <input type="date" className="form-control" placeholder="Tanggal Berakhir" value={this.state.expireddate} onChange={(e) => { this.handleChange(e, 'expireddate') }} required />
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div>Kategori</div>
                    <div className="mt-1 mb-2">
                      <div className='input-group'>
                        <input type="text" className="form-control" placeholder="Masukan Nama Promo" value={this.state.namecategory} onChange={(e) => { this.handleChange(e, 'namecategory') }} disabled required />
                        <button className='btn btn-secondary' onClick={(e) => { this.searchCategory(e) }}>Cari</button>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div>Deskripsi</div>
                    <div className="mt-1 mb-2">
                      <textarea className="form-control" placeholder="Masukan Deskripsi" rows="5" value={this.state.desc || ''} onChange={(e) => { this.handleChange(e, 'desc') }} />
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div>Harga Awal</div>
                    <div className="mt-1 mb-2">
                      <input type="text" className="form-control" placeholder="Harga Sebelum Diskon" value={this.state.pricebefore} onChange={(e) => { this.handleChange(e, 'pricebefore') }} required />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div>Harga Promo</div>
                    <div className="mt-1 mb-2">
                      <input type="text" className="form-control" placeholder="Harga Sesudah Diskon" value={this.state.priceafter} onChange={(e) => { this.handleChange(e, 'priceafter') }} required />
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div>Nominal Potongan</div>
                    <div className="mt-1 mb-2">
                      <input type="text" className="form-control" placeholder="Total Diskon" value={this.state.pricediff} onChange={(e) => { this.handleChange(e, 'pricediff') }} disabled={true} />
                    </div>
                  </div>

                  <div className='col-12'>
                    <div>Upload Images</div>
                    <div className="mt-1 mb-2">
                      <input id="edtInputImage" accept="image/*" type="file" style={{ display: 'none' }} onChange={(e) => { this.uploadImage(e) }}></input>
                      <img id="imgView" className="img-thumbnail mb-1" style={{ width: '200px', height: '120px', objectFit: 'fill' }} alt=""
                        onClick={() => { document.getElementById('edtInputImage').click(); }}
                        onError={(e) => { e.target.src = imgnotfound; }}
                      />
                    </div>
                  </div>

                  <div className='col-12'>
                    <div>Tampilkan Harga</div>
                    <div className="mt-1 mb-2">
                      <div className="form-check form-switch">
                        <input className="form-check-input"
                          type="checkbox"
                          checked={this.state.isshowprice === "1"}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              e.target.value = "1"
                            } else {
                              e.target.value = "0"
                            }
                            this.setState({ isshowprice: e.target.value })
                          }} />
                      </div>
                    </div>
                  </div>

                  <div className='col-12'>
                    <div>Tanggal Kadaluarsa</div>
                    <div className="mt-1 mb-2">
                      <div className="form-check form-switch">
                        <input className="form-check-input"
                          type="checkbox"
                          checked={this.state.isshowexpired === "1"}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              e.target.value = "1"
                            } else {
                              e.target.value = "0"
                            }
                            this.setState({ isshowexpired: e.target.value })
                          }} />
                      </div>
                    </div>
                  </div>

                  <div className='col-12'>
                    <div>Kirim notifikasi ke pengguna</div>
                    <div className="mt-1 mb-2">
                      <div className="form-check form-switch">
                        <input className="form-check-input"
                          type="checkbox"
                          checked={this.state.issendnotif === "1"}
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              e.target.value = "1"
                            } else {
                              e.target.value = "0"
                            }
                            this.setState({ issendnotif: e.target.value })
                          }} />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-4 d-flex">
                    <button type="submit" className="btn btn-warning text-light me-1" >Simpan</button>
                    <button type="button" className="btn btn-outline-warning"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/master_promo_item`)
                      }}
                    >Batalkan</button>
                  </div>

                </div>
                {/* Form Inputing */}

              </form>
            </div>
          </div>
        </div >
      )
    }
  }
  function EditData() {
    let { id } = useParams();
    return <InputData mode="edit" iddata={id} />
  }

  return (
    <Routes>
      <Route index element={<ViewBrowse />} />
      <Route path={`/input`} element={<InputData mode="add" iddata="0" />} />
      <Route path={`/input/:id`} element={<EditData />} />
    </Routes>
  )
}

export default App;