import React, { Fragment } from 'react';
import {
  Route,
  Routes,
  useParams,
  useNavigate,
} from 'react-router-dom';

import { GetCokkiee, RequestHttp } from '../utils/Main.js';
import { ConfirmDialog, AlertDialog, Toast, TableData, EntityPagging } from '../component/Component.js';


const nameEntityPagging = 'table_master_promo_category'
function App() {
  let navigate = useNavigate();

  // browse data
  class ViewBrowse extends React.Component {
    constructor(props) {
      super(props);
      this.modelData = new EntityPagging(nameEntityPagging)
      this.modelData.setdefaultcolumn('a.ID')
      this.state = {
        viewTable: 'loading...',
        search: this.modelData.get('search') || '',
      };

    }
    componentDidMount() {
      this.previewData();
    }
    handleChange(e, name) {
      this.setState({ [name]: e.target.value });
      if (name === 'searchcount') {
        this.modelData.set('count', e.target.value);
        this.previewData()
      }
    }
    changeSort(e, iddata, modesort) {
      e.preventDefault();
      const ctx = this;
      RequestHttp('/api_admin_v1/changesort_category_promo', {
        token: GetCokkiee('token'),
        iddata: iddata,
        modesort: modesort,
      }, (data) => {
        setTimeout(() => {
          if (data.status === 'true') {
            ctx.modelData.reset()
            ctx.previewData();
          } else if (data.status === 'false') {
            Toast(data.message, '');
          } else {
            Toast('Terjadi kesalahan, silahkan coba lagi', '');
          }
        }, 100);
      }, (error) => {
        console.log(error);
      });
    }
    deleteData(e, iddata) {
      e.preventDefault();
      const ctx = this;
      ConfirmDialog('', 'Anda yakin ingin menghapus data ini ?',
        (ctxdialog) => {
          RequestHttp('/api_admin_v1/delete_category_promo', {
            token: GetCokkiee('token'),
            iddata: iddata
          }, (data) => {
            ctxdialog.hide();
            setTimeout(() => {
              if (data.status === 'true') {
                ctx.modelData.reset()
                ctx.previewData();
                Toast(data.message, (ctxalert) => { ctxalert.hide(); });
              } else if (data.status === 'false') {
                Toast(data.message, '');
              } else {
                Toast('Terjadi Kesalahan', '');
              }
            }, 100);
          }, (error) => {
            console.log(error);
          });
        }, (ctxdialog) => {
          ctxdialog.hide();
        }
      );
    }
    previewData() {
      const ctx = this;
      RequestHttp('/api_admin_v1/list_category_promo', {
        search: this.modelData.get('search'),
        searchcount: this.modelData.get('count'),
        index: this.modelData.get('index'),
        sortcolumn: this.modelData.get('sortcoumn'),
        sortorder: this.modelData.get('sortorder'),
      }, (data) => {
        if (data.status === 'true') {
          ctx.tableContent(data)
        } else if (data.status === 'false') {
          Toast(data.message, '');
        } else {
          Toast('Terjadi kesalahan, silahkan coba lagi', '');
        }
      }, (error) => {
        console.log(error);
      });
    }
    tableContent(data) {
      const ctx = this;
      const tdAction = function (itemdata) {
        return <Fragment>
          <button className="btn btn-outline-light btn-border-none"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/master_promo_category/input/${itemdata.ID}`)
            }}>
            <span className="material-icons d-flex" style={{ color: 'grey' }}>
              edit
            </span>
          </button>
          <button className="btn btn-outline-light btn-border-none"
            onClick={(e) => { ctx.deleteData(e, itemdata.ID) }}>
            <span className="material-icons d-flex" style={{ color: 'grey' }}>
              delete
            </span>
          </button>
        </Fragment>
      }
      const clickRow = function (e, item) {
        e.preventDefault();
        navigate(`/master_promo_category/input/${item.ID}`)
      }

      const tableData = {
        header: [
          { caption: 'Action' },
          { caption: 'Nama Kategori Promo', sort: '' },
          { caption: 'Tanggal Buat', sort: '' },
          { caption: 'Tanggal Edit', sort: '' },
          { caption: 'Admin', sort: '' },
        ],
        body: [
          { name: tdAction, type: 'view', css: 'td-actions' },
          { name: 'NameCategory', click: true },
          { name: 'TimeCreated1', click: true },
          { name: 'TimeUpdated1', click: true },
          { name: 'NameAdmin', click: true },
        ],
      }

      this.setState({
        viewTable: <TableData
          ctx={this}
          nameModelData={nameEntityPagging}
          tableData={tableData}
          dataset={data.data}
          ctpages={data.ctpages}
          ctdata={data.ctdata}
          clickRow={clickRow}
        />
      });
    }
    render() {
      return (
        <div className='background-input' style={{ padding: '30px' }}>

          {/* search */}
          <div className="row justify-content-between">
            <div className="col-12 col-md-3 mb-1">
              <button
                className="btn btn-warning text-light "
                onClick={(e) => {
                  e.preventDefault()
                  this.modelData.reset()
                  navigate(`/master_promo_category/input`)
                }}>
                Buat Kategori Promo
              </button>
            </div>
            <div className="col-12 col-md-4 mb-1">
              <div className="input-group">
                <select className="form-select"
                  value={this.state.searchcount}
                  onChange={(e) => { this.handleChange(e, 'searchcount') }}
                  style={{ maxWidth: '80px' }}
                >
                  <option value="10" >10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <input type="text" className="form-control" placeholder="Pencarian"
                  value={this.state.search}
                  onChange={(e) => { this.handleChange(e, 'search') }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.modelData.reset()
                      this.modelData.set('search', this.state.search)
                      this.previewData()
                    }
                  }}
                />
                <button className='btn btn-warning text-light'
                  onClick={(e) => {
                    e.preventDefault()
                    this.modelData.reset()
                    this.modelData.set('search', this.state.search)
                    this.previewData()
                  }}
                >
                  <span className="material-icons d-flex">
                    search
                  </span>
                </button>
              </div>
            </div>

          </div>
          {/* table content */}
          <div className="mt-3">
            {this.state.viewTable}
          </div>

        </div >
      )
    }
  }

  // input data
  class InputData extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        code: '',
        name: '',

        mode: props.mode,
        iddata: props.iddata,
      };
    }
    componentDidMount() {
      if (this.state.mode === 'edit') {
        const ctxself = this;
        const param = {
          token: GetCokkiee('token'),
          iddata: this.state.iddata,
        }
        RequestHttp('/api_admin_v1/view_category_promo', param,
          function (data) {
            setTimeout(() => {
              if (data.status === 'true') {
                if (data.data.length > 0) {
                  ctxself.setState({
                    name: data.data[0].NameCategory,
                  })
                } else {
                  AlertDialog('', 'Data tidak ditemukan', (ctxdialog) => {
                    ctxdialog.hide();
                    navigate('/master_promo_category');
                  });
                }
              } else {
                Toast(data.message, '');
              }
            }, 100);
          }, function (error) {
            console.log(error);
          }
        );
      }
    }
    handleChange(event, name) {
      this.setState({ [name]: event.target.value });
    }
    saveData(e) {
      e.preventDefault();
      if (this.state.name === '') {
        Toast('Mohon isi data dengan lengkap', '');
      } else {
        ConfirmDialog('', 'Anda yakin ingin menyimpan data ini ?',
          (ctxdialog) => {
            const urlinput = (this.state.mode === 'add') ? '/api_admin_v1/insert_category_promo' : '/api_admin_v1/update_category_promo'
            const param = {
              token: GetCokkiee('token'),
              iddata: this.state.iddata,
              name: this.state.name,
              layout: ''
            }
            RequestHttp(urlinput, param,
              function (data) {
                ctxdialog.hide();
                setTimeout(() => {
                  if (data.status === 'true') {
                    Toast(data.message, '');
                    navigate('/master_promo_category');
                  } else if (data.status === 'false') {
                    Toast(data.message, '');
                  } else {
                    Toast('Terjadi Kesalahan', '');
                  }
                }, 100);
              }, function (error) {
                console.log(error);
              }
            );
          }, (ctxdialog) => { // action no
            ctxdialog.hide();
          }
        );
      }
    }
    render() {
      return (
        <div className='background-input' style={{ padding: '30px', minHeight: '93vh' }}>
          <h4 style={{ fontWeight: 'bold' }}>{(this.state.mode === 'add') ? 'Tambah' : 'Edit'} Kategori Promo</h4>
          <div className="card">
            <div className="card-body" style={{ padding: '50px' }}>
              <form onSubmit={(e) => { this.saveData(e) }}>

                {/* Form Inputing */}
                <div className="row">
                  <div className="col-12 ">
                    <div>Nama Kategori</div>
                    <div className="mt-1 mb-2">
                      <input type="text" className="form-control" placeholder="Nama" value={this.state.name} onChange={(e) => { this.handleChange(e, 'name') }} required />
                    </div>
                  </div>

                  <div className="col-12 mt-4 d-flex">
                    <button type="submit" className="btn btn-warning text-light me-1" >Simpan</button>
                    <button type="button" className="btn btn-outline-warning"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/master_promo_category`)
                      }}
                    >Batalkan</button>
                  </div>
                </div>
                {/* Form Inputing */}

              </form>
            </div>
          </div >
        </div >
      )
    }
  }
  function EditData() {
    let { id } = useParams();
    return <InputData mode="edit" iddata={id} />
  }

  return (
    <Routes>
      <Route index element={<ViewBrowse />} />
      <Route path={`/input`} element={<InputData mode="add" iddata="0" />} />
      <Route path={`/input/:id`} element={<EditData />} />
    </Routes>
  )
}

export default App;