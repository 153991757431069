import React from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { GetCokkiee, RequestHttp } from '../utils/Main.js';
import { ConfirmDialog, Toast } from '../component/Component.js';

function App() {
  let navigate = useNavigate();

  class ChangePassword extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        oldpassword: '',
        newpassword: '',
        newpassword1: '',
        viewInputMenu: <div></div>
      };
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, name) {
      this.setState({ [name]: event.target.value });
    }

    saveData(e) {
      e.preventDefault();
      ConfirmDialog('', 'Anda yakin ingin mengganti password ?',
        (ctxdialog) => {

          const param = {
            token: GetCokkiee('token'),
            oldpassword: this.state.oldpassword,
            newpassword: this.state.newpassword,
            newpassword1: this.state.newpassword1
          }
          let urlinput = '/api_admin_v1/change_password_admin';
          console.log(urlinput);
          RequestHttp(urlinput, param,
            function (data) {
              ctxdialog.hide();
              setTimeout(() => {
                if (data.status === 'true') {
                  Toast(data.message, '');
                  navigate('/change_password')
                } else Toast(data.message, '');
              }, 100);
            }, function (error) {
              console.log(error);
            }
          );
        }, (ctxdialog) => { // action no
          ctxdialog.hide();
        }
      );
    }
    render() {
      return (
        <div style={{ padding: '10px' }}>
          <h5>Ganti Password</h5>
          <div className="card">
            <div className="card-body">
              {/* Form Inputing */}
              <form onSubmit={(e) => { this.saveData(e) }}>
                <div className="row">

                  <div className="col-md-12 mb-2">
                    <div className="row">
                      <div className="col-md-2">Password saat ini</div>
                      <div className="col-md-4">
                        <input type="password" className="form-control" placeholder="Masukan password saat ini" value={this.state.oldpassword} onChange={(e) => { this.handleChange(e, 'oldpassword') }} required />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-2">
                    <div className="row">
                      <div className="col-md-2">Password Baru</div>
                      <div className="col-md-4">
                        <input type="password" className="form-control" placeholder="Masukan password baru" value={this.state.newpassword} onChange={(e) => { this.handleChange(e, 'newpassword') }} required />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-2">
                    <div className="row">
                      <div className="col-md-2">Ketik ulang password baru</div>
                      <div className="col-md-4">
                        <input type="password" className="form-control" placeholder="Masukan password baru" value={this.state.newpassword1} onChange={(e) => { this.handleChange(e, 'newpassword1') }} required />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-2">
                    <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col">
                        <div className="d-flex">
                          <button type="submit" className="btn btn-primary me-1" >Ganti Password</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Form Inputing */}
                </div>

              </form>
            </div>
          </div>
        </div >
      )
    }
  }

  return (
    <Routes>
      <Route index element={<ChangePassword />} />
    </Routes>
  )
}

export default App;