export const EntityUnit = class {
    constructor(ID = '', CodeUnit = '', NameUnit = '', IDProduct = 0, Rank = 0, Qty = 0, Price = 0) {
        this.ID = ID;
        this.CodeUnit = CodeUnit;
        this.NameUnit = NameUnit;
        this.IDProduct = IDProduct;
        this.Rank = Rank;
        this.Qty = Qty;
        this.Price = Price;
        return {
            ID: this.ID,
            CodeUnit: this.CodeUnit,
            NameUnit: this.NameUnit,
            IDProduct: this.IDProduct,
            Rank: this.Rank,
            Qty: this.Qty,
            Price: this.Price
        }
    }
}
