import React, { Fragment } from 'react';
import {
  Routes,
  Route,
  useParams,
  useNavigate,
} from 'react-router-dom';

import { ipserver, GetCokkiee, RequestHttp, DateInputNow, SetCokkiee } from '../utils/Main.js';
import { ConfirmDialog, Toast, TableData, EntityPagging } from '../component/Component.js';
import imgnotfound from '../assets/notfound.png';
const urlweb = ipserver;


const nameEntityPagging_tab1 = 'table_transaction_pos_tab1'
const nameEntityPagging_tab2 = 'table_transaction_pos_tab2'
function App() {
  let navigate = useNavigate();

  // browse data
  class ViewBrowse extends React.Component {
    constructor(props) {
      super(props);
      this.modelData_tab1 = new EntityPagging(nameEntityPagging_tab1)
      this.modelData_tab2 = new EntityPagging(nameEntityPagging_tab2)
      this.state = {
        viewTable_tab1: 'loading...',
        viewTable_tab2: 'loading...',
        search_tab1: this.modelData_tab1.get('search') || '',
        search_tab2: this.modelData_tab2.get('search') || '',

        tabActive: (window.tab_transaction_order === undefined) ? 'tab_1' : window.tab_transaction_order,
      };
    }
    componentDidMount() {
      this.previewData();
    }
    handleChange(e) {
      this.setState({ [e.target.name]: e.target.value });

      if (this.state.tabActive === 'tab_1') {
        if (e.target.name === 'search_tab1') {
          this.modelData_tab1.set('search', e.target.value);
        } else if (e.target.name === 'searchcount_tab1') {
          this.modelData_tab1.set('count', e.target.value);
          this.previewData()
        }
      } else {
        if (e.target.name === 'search_tab2') {
          this.modelData_tab2.set('search', e.target.value);
        } else if (e.target.name === 'searchcount_tab2') {
          this.modelData_tab2.set('count', e.target.value);
          this.previewData()
        }
      }

    }
    changeTab(modetab) {
      window.tab_transaction_order = modetab
      this.setState({ tabActive: modetab })
      if ((modetab === 'tab_1') && (this.state.viewTable_tab1 === 'loading...')) {
        setTimeout(() => {
          this.previewData()
        }, 100);
      }
      if ((modetab === 'tab_2') && (this.state.viewTable_tab2 === 'loading...')) {
        setTimeout(() => {
          this.previewData()
        }, 100);
      }
    }
    previewData() {
      const ctx = this
      let params = {}
      if (this.state.tabActive === 'tab_1') {
        params = {
          search: this.modelData_tab1.get('search'),
          searchcount: this.modelData_tab1.get('count'),
          index: this.modelData_tab1.get('index'),
          sortcolumn: this.modelData_tab1.get('sortcoumn'),
          sortorder: this.modelData_tab1.get('sortorder'),
          typedata: 'on_process'
        }
      } else {
        params = {
          search: this.modelData_tab2.get('search'),
          searchcount: this.modelData_tab2.get('count'),
          index: this.modelData_tab2.get('index'),
          sortcolumn: this.modelData_tab2.get('sortcoumn'),
          sortorder: this.modelData_tab2.get('sortorder'),
          typedata: 'has_finish'
        }
      }
      RequestHttp('/api_admin_v1/list_transaction_order', params, (data) => {
        if (data.status === 'true') {
          ctx.tableContent(data)
        } else if (data.status === 'false') {
          Toast(data.message, '')
        } else {
          Toast('Terjadi Kesalahan', '')
        }
      }, (error) => {
        console.log(error);
      });
    }
    tableContent(data) {
      const clickRow = function (e, item) {
        e.preventDefault();
        navigate(`/transaction_order/input/${item.DocNumber}`)
      }

      const tableData = {
        header: [
          // { caption: 'Action' },
          { caption: 'Tanggal', sort: '', css: 'td-word-wrap' },
          { caption: 'No. Transaksi', sort: '', css: 'td-word-wrap' },
          { caption: 'Customer', sort: '', css: 'td-word-wrap' },
          { caption: 'Jumlah Barang', sort: '', css: 'td-word-wrap' },
          { caption: 'Grandtotal', sort: '', css: 'td-word-wrap' },
          { caption: 'Jenis Order', sort: '', css: 'td-word-wrap' },
          { caption: 'Status', sort: '', css: 'td-word-wrap' },
        ],
        body: [
          // { name: tdAction, type: 'view', css: 'td-actions' },
          { name: 'DocDate', click: true },
          { name: 'DocNumber', click: true },
          { name: 'NameMember', click: true },
          { name: 'CountProduct', click: true },
          { name: 'GrandTotal', click: true },
          { name: 'TypeOrder', click: true },
          { name: 'StatusTransaksi', click: true },
        ],
      }

      if (this.state.tabActive === 'tab_1') {
        this.setState({
          viewTable_tab1: <TableData
            ctx={this}
            nameModelData={nameEntityPagging_tab1}
            tableData={tableData}
            dataset={data.data}
            ctpages={data.ctpages}
            ctdata={data.ctdata}
            clickRow={clickRow}
          />,
        });
      } else {
        this.setState({
          viewTable_tab2: <TableData
            ctx={this}
            nameModelData={nameEntityPagging_tab2}
            tableData={tableData}
            dataset={data.data}
            ctpages={data.ctpages}
            ctdata={data.ctdata}
            clickRow={clickRow}
          />,
        });
      }

    }
    render() {
      return (
        <div className='background-input' style={{ padding: '30px' }}>

          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <button className={(this.state.tabActive === 'tab_1') ? "nav-link active" : "nav-link"} onClick={(e) => this.changeTab('tab_1')} >Sedang Diproses</button>
            </li>
            <li className="nav-item">
              <button className={(this.state.tabActive === 'tab_2') ? "nav-link active" : "nav-link"} onClick={(e) => this.changeTab('tab_2')} >Selesai</button>
            </li>
          </ul>

          {
            (this.state.tabActive === 'tab_1') &&
            <div>
              {/* search */}
              <div className="row justify-content-between">
                <div className="col-12 col-md-6 mb-1">
                  <div className="input-group">
                    <select className="form-select"
                      name='searchcount_tab1'
                      value={this.state.searchcount_tab1}
                      onChange={(e) => this.handleChange(e)}
                      style={{ maxWidth: '80px' }}
                    >
                      <option value="10" >10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    <input type="text" className="form-control" placeholder="Pencarian"
                      name="search_tab1"
                      value={this.state.search_tab1}
                      onChange={(e) => this.handleChange(e)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.modelData_tab1.reset()
                          this.previewData()
                        }
                      }}
                    />
                    <button className='btn btn-warning text-light'
                      onClick={(e) => {
                        e.preventDefault()
                        this.modelData_tab1.reset()
                        this.previewData()
                      }}
                    >
                      <span className="material-icons d-flex">
                        search
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {/* table content */}
              <div className="mt-3">
                {this.state.viewTable_tab1}
              </div>

            </div>
          }

          {
            (this.state.tabActive === 'tab_2') &&
            <div>
              {/* search */}
              <div className="row justify-content-between">
                <div className="col-12 col-md-6 mb-1">
                  <div className="input-group">
                    <select className="form-select"
                      name='searchcount_tab2'
                      value={this.state.searchcount_tab2}
                      onChange={(e) => this.handleChange(e)}
                      style={{ maxWidth: '80px' }}
                    >
                      <option value="10" >10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    <input type="text" className="form-control" placeholder="Pencarian"
                      name="search_tab2"
                      value={this.state.search_tab2}
                      onChange={(e) => this.handleChange(e)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.modelData_tab2.reset()
                          this.previewData()
                        }
                      }}
                    />
                    <button className='btn btn-warning text-light'
                      onClick={(e) => {
                        e.preventDefault()
                        this.modelData_tab2.reset()
                        this.previewData()
                      }}
                    >
                      <span className="material-icons d-flex">
                        search
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {/* table content */}
              <div className="mt-3">
                {this.state.viewTable_tab2}
              </div>

            </div>
          }

        </div >
      )
    }
  }

  // inputing data   
  class ViewInputingData extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        docnumber: props.docnumber,
        docdate: DateInputNow(),
        total: 0,
        grandtotal: 0,
        membercode: '',
        membername: '',
        telp: '',
        kodepembayaran: '',
        paymentname: '',
        statusdoc: '',
        typeorder: '',
        typeorderdesc: '',
        statustransaction: '',
        filepembayaran: '',

        Shipping_NameRecipient: '',
        Shipping_Telp: '',
        Shipping_Address: '',
        Shipping_Province: '',
        Shipping_City: '',
        Shipping_SubDistrict: '',
        Shipping_PostalCode: '',
        Shipping_RajaOngkirIDProvince: '',
        Shipping_RajaOngkirIDCity: '',
        Shipping_RajaOngkirIDSubDistrict: '',

        urlimg: '',

        viewListProduct: <tr></tr>
      };
    }
    componentDidMount() {
      this.previewData()
    }
    previewData() {
      const ctx = this
      RequestHttp('/api_admin_v1/view_transaction_order', {
        token: GetCokkiee('token'),
        docnumber: this.state.docnumber
      }, (data) => {
        if (data.status === 'true') {

          ctx.setState({
            docdate: data.data_header.DocDate,
            total: data.data_header.Total,
            grandtotal: data.data_header.GrandTotal,
            membercode: data.data_header.CodeMember,
            membername: data.data_header.NameMember,
            telp: data.data_header.Telp,
            kodepembayaran: data.data_header.KodePembayaran,
            paymentname: data.data_header.NamaPembayaran,
            statusdoc: data.data_header.StatusDoc,
            typeorder: data.data_header.TypeOrder,
            typeorderdesc: data.data_header.TypeOrderDesc,
            statustransaction: data.data_header.StatusTransaksi,
            filepembayaran: data.data_header.FilePembayaran,

            Shipping_Address: data.data_header.Shipping_Address,
            Shipping_City: data.data_header.Shipping_City,
            Shipping_NameRecipient: data.data_header.Shipping_NameRecipient,
            Shipping_PostalCode: data.data_header.Shipping_PostalCode,
            Shipping_Province: data.data_header.Shipping_Province,
            Shipping_RajaOngkirIDCity: data.data_header.Shipping_RajaOngkirIDCity,
            Shipping_RajaOngkirIDProvince: data.data_header.Shipping_RajaOngkirIDProvince,
            Shipping_RajaOngkirIDSubDistrict: data.data_header.Shipping_RajaOngkirIDSubDistrict,
            Shipping_SubDistrict: data.data_header.Shipping_SubDistrict,
            Shipping_Telp: data.data_header.Shipping_Telp,

            urlimg: (data.data_header.FilePembayaran !== '') ? urlweb + '/api_module/file/' + data.data_header.FilePembayaran : ''
          })

          setTimeout(() => {
            if (this.state.kodepembayaran === 'transfermanual') {
              document.getElementById('imgProof').src = this.state.urlimg;
            }
          }, 100);


          const dataProduct = data.data_detail
          if (dataProduct.length > 0) {
            let recno = 0
            const viewListProduct = dataProduct.map((item, index) => {
              recno++




              return <tr key={index}>
                <td>{recno}</td>
                <td><img src={`${urlweb}/api_module/file_product/${item.Img}`} className="img-thumbnail" style={{ objectFit: 'contain', width: '100px', height: '100px' }} onError={(e) => { e.target.src = imgnotfound; }}></img></td>
                <td>{item.NameProduct}</td>
                <td>{item.Qty} {item.NameUnit}</td>
                <td>{item.Price}</td>
                <td>{item.Discount}</td>
                <td>{item.Total}</td>
                <td>{item.StatusData}</td>
                {(this.state.statusdoc === 'waiting_confirm')
                  && <td>
                    <button className='ms-1 mr-1 btn btn-primary mb-1' onClick={(e) => ctx.approvalDetail(e, item.ID, 'accept')}>Tersedia</button>
                    <button className='ms-1 mr-1 btn btn-warning mb-1' onClick={(e) => ctx.approvalDetail(e, item.ID, 'reject')}>Habis</button>
                  </td>
                }

              </tr>
            })
            ctx.setState({
              viewListProduct: viewListProduct
            })
          }

        } else if (data.status === 'false') {
          Toast(data.message, '');
          if (data.action === 'transaction_invalid') {
            navigate(`/transaction_order`)
          }
        } else {
          Toast('Terjadi Kesalahan', '')
        }
      }, (error) => {
        console.log(error);
      });
    }
    handleChange(e, name) {
      this.setState({ [name]: e.target.value });
    }
    saveData(e, mode) {
      e.preventDefault();
      var startdate = new Date(this.state.startdate);
      var expireddate = new Date(this.state.expireddate);
      if (startdate > expireddate) {
        Toast('Tanggal Mulai harus lebih kecil dari pada tanggal kadaluarsa', '');
      } else {
        ConfirmDialog('', 'Anda yakin ingin menyimpan data ini ?',
          (ctxdialog) => {
            RequestHttp('/api_admin_v1/update_status_transaction_order', {
              token: GetCokkiee('token'),
              // iddata: this.state.iddata,
              // name: this.state.name,
              // desc: this.state.desc,
              // img: this.state.img,
              // startdate: this.state.startdate,
              // expireddate: this.state.expireddate,
              docnumber: this.state.docnumber,
              actionmode: mode
            }, (data) => {
              ctxdialog.hide();
              setTimeout(() => {
                if (data.status === 'true') {
                  Toast(data.message, '');
                  this.previewData()
                } else if (data.status === 'false') {
                  Toast(data.message, '');
                } else {
                  Toast('Terjadi Kesalahan', '');
                }
              }, 100);
            }, (error) => {
              console.log(error);
            });
          }, (ctxdialog) => { // action no
            ctxdialog.hide();
          }
        );
      };
    }
    approvalDetail(e, iddata, mode) {
      e.preventDefault()
      const ctx = this
      ConfirmDialog('', (mode === 'accept') ? 'Anda yakin ingin menyetujui data ini ?' : 'Anda yakin ingin menolak data ini ?',
        (ctxdialog) => {
          RequestHttp('/api_admin_v1/update_product_order_checklist', {
            token: GetCokkiee('token'),
            docnumber: ctx.state.docnumber,
            iddata: iddata,
            mode: mode,
          }, (data) => {
            ctxdialog.hide();
            ctx.previewData()
          }, (error) => {
            console.log(error);
          });
        }, (ctxdialog) => {
          ctxdialog.hide();
        }
      );
    }
    render() {
      return (
        <div className='background-input' style={{ padding: '30px' }}>
          <h4 style={{ fontWeight: 'bold' }}>Data Transaksi</h4>
          <div className="card">
            <div className="card-body">
              {/* <form onSubmit={(e) => { this.saveData(e) }}> */}

              <h5><b>*Informasi Transaksi</b></h5>
              <hr />
              {/* Form Inputing */}
              <div className="row mb-3">

                <div className='col-md-6'>
                  <div>Nomor Transaksi</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.docnumber} onChange={(e) => { this.handleChange(e, 'docnumber') }} disabled={true} />
                  </div>
                </div>

                <div className='col-md-6'>
                  <div>Tanggal Transaksi</div>
                  <div className="mt-1 mb-2">
                    <input type="date" className="form-control" value={this.state.docdate} onChange={(e) => { this.handleChange(e, 'docdate') }} disabled={true} />
                  </div>
                </div>

                <div className='col-md-6'>
                  <div>Kode Member</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.membercode} onChange={(e) => { this.handleChange(e, 'membercode') }} disabled={true} />
                  </div>
                </div>

                <div className='col-md-6'>
                  <div>Nama Customer</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.membername} onChange={(e) => { this.handleChange(e, 'membername') }} disabled={true} />
                  </div>
                </div>

                <div className='col-md-6'>
                  <div>No. Handphone</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.telp} onChange={(e) => { this.handleChange(e, 'telp') }} disabled={true} />
                  </div>
                </div>

                <div className='col-md-6'>
                  <div>Status Transaksi</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.statustransaction} onChange={(e) => { this.handleChange(e, 'statustransaction') }} disabled={true} />
                  </div>
                </div>

                <div className='col-md-6'>
                  <div>Tipe Order</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.typeorderdesc} onChange={(e) => { this.handleChange(e, 'typeorderdesc') }} disabled={true} />
                  </div>
                </div>


              </div>

              <h5><b>*Informasi Pembayaran</b></h5>
              <hr />
              <div className='row mb-3'>
                {/* {(this.state.kodepembayaran === 'transfermanual')
                  && <div className='col-md-2'>
                    <div>Bukti Pembayaran</div>
                    <div className="mt-1 mb-2">
                      <a href={this.state.urlimg} target="_blank">
                        <img id='imgProof' className='img-thumbnail' style={{ width: '200px', height: '200px', objectFit: 'cover' }} onError={(e) => { e.target.src = imgnotfound; }}></img>
                      </a>
                    </div>
                  </div>
                } */}
                <div className='col'>
                  <div>Jenis Pembayaran</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.paymentname} onChange={(e) => { this.handleChange(e, 'paymentname') }} disabled={true} />
                  </div>
                </div>


              </div>

              <h5><b>*Informasi Pengiriman</b></h5>
              <hr />
              <div className='row mb-3'>
                <div className='col-md-6'>
                  <div>Nama Penerima</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.Shipping_NameRecipient} onChange={(e) => { }} disabled={true} />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div>Telepon Penerima</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.Shipping_Telp} onChange={(e) => { }} disabled={true} />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div>Alamat</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.Shipping_Address} onChange={(e) => { }} disabled={true} />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div>Kota</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.Shipping_City} onChange={(e) => { }} disabled={true} />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div>Kecamatan</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.Shipping_SubDistrict} onChange={(e) => { }} disabled={true} />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div>Kode Pos</div>
                  <div className="mt-1 mb-2">
                    <input type="text" className="form-control" value={this.state.Shipping_PostalCode} onChange={(e) => { }} disabled={true} />
                  </div>
                </div>

              </div>

              <div className='mt-3'>
                <h4 style={{ fontWeight: 'bold' }}>Barang Pemesanan</h4>

                <div style={{ overflow: 'auto' }}>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col" className='td-word-wrap'>#</th>
                        <th scope="col" className='td-word-wrap'>Gambar</th>
                        <th scope="col" className='td-word-wrap'>Nama Produk</th>
                        <th scope="col" className='td-word-wrap'>Qty</th>
                        <th scope="col" className='td-word-wrap'>Harga</th>
                        <th scope="col" className='td-word-wrap'>Dikson</th>
                        <th scope="col" className='td-word-wrap'>Total</th>
                        <th scope="col" className='td-word-wrap'>Status</th>
                        {(this.state.statusdoc === 'waiting_confirm') && <th scope="col">Aksi</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.viewListProduct}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='mt-2'>
                {(this.state.statusdoc === 'waiting_confirm')
                  && <Fragment>
                    <button type="button" className='btn btn-primary ms-1 me-1 mb-2' onClick={(e) => this.saveData(e, 'process_order')}>Konfirmasi Pesanan</button>
                    <button type="button" className='btn btn-danger ms-1 me-1 mb-2' onClick={(e) => this.saveData(e, 'cancel_transaction')}>Batalkan Transaksi</button>
                  </Fragment>
                }
                {(this.state.statusdoc === 'process_order')
                  && <Fragment>
                    <button type="button" className='btn btn-primary ms-1 me-1 mb-2' onClick={(e) => this.saveData(e, 'finish_transaction')}>Transaksi Selesai</button>
                    <button type="button" className='btn btn-danger ms-1 me-1 mb-2' onClick={(e) => this.saveData(e, 'cancel_transaction')}>Batalkan Transaksi</button>
                  </Fragment>
                }

                {/* {(this.state.statusdoc === 'payment_reject')
                    && <Fragment>
                      <button type="button" className='btn btn-danger ms-1 me-1' onClick={(e) => this.saveData(e, 'cancel_transaction')}>Batalkan Transaksi</button>
                    </Fragment>
                  }
                  {(this.state.statusdoc === 'waiting_payment_accept')
                    && <Fragment>
                      <button type="button" className='btn btn-primary ms-1 me-1' onClick={(e) => this.saveData(e, 'accept_payment')}>Konfirmasi Pembayaran</button>
                      <button type="button" className='btn btn-danger ms-1 me-1' onClick={(e) => this.saveData(e, 'reject_transaction')}>Tolak Pembayaran</button>
                      <button type="button" className='btn btn-danger ms-1 me-1' onClick={(e) => this.saveData(e, 'cancel_transaction')}>Batalkan Transaksi</button>
                    </Fragment>
                  }
                  {((this.state.statusdoc === 'process_order') && (this.state.typeorder === 'pickup'))
                    && <Fragment>
                      <button type="button" className='btn btn-success ms-1 me-1' onClick={(e) => this.saveData(e, 'process_is_ready')}>Barang Telah Disiapkan</button>
                      <button type="button" className='btn btn-danger ms-1 me-1' onClick={(e) => this.saveData(e, 'cancel_transaction')}>Batalkan Transaksi</button>
                    </Fragment>
                  }
                  {((this.state.statusdoc === 'process_order') && (this.state.typeorder === 'delivery'))
                    && <Fragment>
                      <button type="button" className='btn btn-primary ms-1 me-1' onClick={(e) => this.saveData(e, 'process_delivery')}>Proses Pengiriman</button>
                      <button type="button" className='btn btn-danger ms-1 me-1' onClick={(e) => this.saveData(e, 'cancel_transaction')}>Batalkan Transaksi</button>
                    </Fragment>
                  } */}
                {/* {(this.state.statusdoc === 'process_shipping')
                    && <Fragment>
                      <button type="button" className='btn btn-primary ms-1 me-1' onClick={(e) => this.saveData(e, 'finish_transaction')}>Transaksi Selesai</button>
                      <button type="button" className='btn btn-danger ms-1 me-1' onClick={(e) => this.saveData(e, 'cancel_transaction')}>Batalkan Transaksi</button>
                    </Fragment>
                  } */}
                {/* {(this.state.statusdoc === 'order_is_ready')
                    && <Fragment>
                      <button type="button" className='btn btn-primary ms-1 me-1' onClick={(e) => this.saveData(e, 'finish_transaction')}>Transaksi Selesai</button>
                      <button type="button" className='btn btn-danger ms-1 me-1' onClick={(e) => this.saveData(e, 'cancel_transaction')}>Batalkan Transaksi</button>
                    </Fragment>
                  } */}

              </div>

              {/* Form Inputing */}
            </div>
          </div >
        </div >
      )
    }
  }
  function Inputing() {
    let { docnumber } = useParams();
    return <ViewInputingData docnumber={docnumber} />
  }

  return (
    <Routes>
      <Route index element={<ViewBrowse />} />
      <Route path={`/input/:docnumber`} element={<Inputing />} />
    </Routes>
  )
}


export default App;