
import React, { Fragment } from 'react';

import { RequestHttp, GetCokkiee } from '../utils/Main.js';
import { Toast } from '../component/Component.js';


export const ModalSearchPromoForTemplate = class ModalSearchPromoForTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            search: '',
            viewTable: 'loading...',

            chooseData: props.chooseData,
            closeModal: props.closeModal
        }
    }
    componentDidMount() {
        document.getElementById('btnModalSearchPromoForTemplate').click();
        this.previewData()

    }
    previewData() {
        const ctx = this
        const param = {
            search: this.state.search
        }
        RequestHttp('/api_admin_v1/search_promotemplate', param,
            function (data) {
                if (data.status === 'true') {
                    ctx.renderList(data.data)
                } else Toast(data.message, '');
            }, function (error) {
                console.log(error);
            })
    }
    renderList(dataPromo) {
        const viewTemp = []
        for (let i = 0; i < dataPromo.length; i++) {
            viewTemp.push(<Fragment key={i}>
                <button type="button"
                    className="list-group-item list-group-item-action"
                    onClick={(e) => { this.chooseData(e, dataPromo[i]) }}>
                    {dataPromo[i].CodePromo + ' - ' + dataPromo[i].NamePromo}
                </button>
            </Fragment>)
        }
        this.setState({ viewTable: viewTemp })

    }
    handleChange(event, name) {
        this.setState({ [name]: event.target.value });
    }
    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.previewData();
        }
    }
    chooseData(e, dataPromo) {
        e.preventDefault()
        this.state.chooseData(dataPromo)
        document.getElementById('modalSearchPromoForTemplateClose').click();
    }
    closeModal(e) {
        e.preventDefault()
        this.state.closeModal()
    }
    render() {
        return <Fragment>

            <button id="btnModalSearchPromoForTemplate" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalSearchPromoForTemplate" style={{ display: 'none' }}>
                Launch demo modal
            </button>

            <div className="modal" id="modalSearchPromoForTemplate" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Cari Promo</h5>
                            <button id="modalSearchPromoForTemplateClose" type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) => { this.closeModal(e) }}></button>
                        </div>
                        <div className="modal-body">

                            <input className="form-control mb-2" placeholder="Cari Produk" value={this.state.search} onChange={(e) => { this.handleChange(e, 'search') }} onKeyPress={(e) => { this.handleKeyDown(e); }} />
                            <div className="list-group">
                                {this.state.viewTable}
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </Fragment >
    }
}

export const ModalSearchMember = class ModalSearchMember extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            search: '',
            viewTable: 'loading...',

            chooseData: props.chooseData,
            closeModal: props.closeModal
        }
    }
    componentDidMount() {
        document.getElementById('btnModalSearchMember').click();
        this.previewData()

    }
    previewData() {
        const ctx = this
        const param = {
            search: this.state.search
        }
        RequestHttp('/api_admin_v1/search_member', param,
            function (data) {
                if (data.status === 'true') {
                    ctx.renderList(data.data)
                } else {
                    Toast(data.message, '');
                }
            }, function (error) {
                console.log(error);
            })
    }
    renderList(dataMember) {
        const viewTemp = []
        for (let i = 0; i < dataMember.length; i++) {
            viewTemp.push(<Fragment key={i}>
                <button type="button"
                    className="list-group-item list-group-item-action"
                    onClick={(e) => { this.chooseData(e, dataMember[i]) }}>
                    {dataMember[i].CodeMember + ' - ' + dataMember[i].NameMember}
                </button>
            </Fragment>)
        }
        this.setState({ viewTable: viewTemp })

    }
    handleChange(event, name) {
        this.setState({ [name]: event.target.value });
    }
    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.previewData();
        }
    }
    chooseData(e, dataMember) {
        e.preventDefault()
        this.state.chooseData(dataMember)
        document.getElementById('modalSearchMemberClose').click();
    }
    closeModal(e) {
        e.preventDefault()
        this.state.closeModal()
    }
    render() {
        return <Fragment>

            <button id="btnModalSearchMember" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalSearchMember" style={{ display: 'none' }}>
                Launch demo modal
            </button>

            <div className="modal" id="modalSearchMember" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Cari Member</h5>
                            <button id="modalSearchMemberClose" type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) => { this.closeModal(e) }}></button>
                        </div>
                        <div className="modal-body">

                            <input className="form-control mb-2" placeholder="Cari Produk" value={this.state.search} onChange={(e) => { this.handleChange(e, 'search') }} onKeyPress={(e) => { this.handleKeyDown(e); }} />
                            <div className="list-group">
                                {this.state.viewTable}
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </Fragment >
    }
}

export const ModalNotifMemberMultiple = class ModalNotifMemberMultiple extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            iddata: props.iddata,
            search: '',
            viewTable: '',
            viewTableMember: '',

            datasetMember: [],

            chooseData: props.chooseData,
            closeModal: props.closeModal
        }
    }
    componentDidMount() {
        document.getElementById('btnModalNotifMemberMultiple').click();
        this.previewData()
    }
    previewData() {
        const ctx = this
        const param = {
            search: this.state.search
        }
        RequestHttp('/api_admin_v1/search_member', param,
            function (data) {
                if (data.status === 'true') {
                    ctx.renderList(data.data)
                } else Toast(data.message, '');
            }, function (error) {
                console.log(error);
            })
    }
    renderList(dataMember) {
        const viewTemp = []
        for (let i = 0; i < dataMember.length; i++) {
            viewTemp.push(<Fragment key={i}>
                <button type="button"
                    className="list-group-item list-group-item-action"
                    onClick={(e) => { this.memberAdd(e, dataMember[i]) }}>
                    {dataMember[i].CodeMember + ' - ' + dataMember[i].NameMember}
                </button>
            </Fragment>)
        }
        this.setState({ viewTable: viewTemp })

    }
    handleChange(event, name) {
        this.setState({ [name]: event.target.value });
    }
    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.previewData();
        }
    }
    memberAdd(e, dataMember) {
        e.preventDefault()
        let datasettemp = this.state.datasetMember

        let flag = true
        for (let i = 0; i < datasettemp.length; i++) {
            if (datasettemp[i].ID === dataMember.ID) {
                flag = false
                break;
            }
        }

        if (flag) {
            datasettemp.push(dataMember)
            this.setState({
                datasetMember: datasettemp
            })
            setTimeout(() => {
                this.memberView()
            }, 100);
        } else {

        }
    }
    memberDelete(e, index) {
        e.preventDefault()
        let datasettemp = this.state.datasetMember

        datasettemp.splice(index, 1)
        this.setState({
            datasetMember: datasettemp
        })
        setTimeout(() => {
            this.memberView()
        }, 100);
    }
    memberView() {
        const dataMember = this.state.datasetMember
        const viewTemp = []
        for (let i = 0; i < dataMember.length; i++) {
            viewTemp.push(<Fragment key={i}>
                <button type="button"
                    className="list-group-item list-group-item-action"
                    onClick={(e) => { this.memberDelete(e, i) }}>
                    {dataMember[i].CodeMember + ' - ' + dataMember[i].NameMember}
                </button>
            </Fragment>)
        }
        this.setState({ viewTableMember: viewTemp })
    }
    memberSendNotif(e) {
        e.preventDefault();
        if (this.state.datasetMember.length > 0) {
            const r = window.confirm('Kirim notif ke pengguna yang dipilih ?')
            if (r) {
                const ctx = this
                const param = {
                    token: GetCokkiee('token'),
                    iddata: this.state.iddata,
                    dataset: this.state.datasetMember
                }
                RequestHttp('/api_admin_v1/notif_member_promo', param,
                    function (data) {
                        if (data.status === 'true') {
                            Toast(data.message, '');
                            ctx.state.closeModal()
                        } else {
                            Toast(data.message, '');
                        }
                    }, function (error) {
                        console.log(error);
                    })
            }
        } else {
            window.alert('Tidak ada member yang dipilih')
        }
    }

    closeModal(e) {
        e.preventDefault()
        this.state.closeModal()
    }
    render() {
        return <Fragment>

            <button id="btnModalNotifMemberMultiple" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalNotifMemberMultiple" style={{ display: 'none' }}>
                Launch demo modal
            </button>

            <div className="modal" id="modalNotifMemberMultiple" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Notifikasi Member</h5>
                            <button id="modalNotifMemberMultipleClose" type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) => { this.closeModal(e) }}></button>
                        </div>
                        <div className="modal-body">

                            <div className="row">
                                <div className="col">
                                    <div className="card">
                                        <div className="card-header">Pencarian Member</div>
                                        <div className="card-body">
                                            <input className="form-control mb-2" placeholder="Cari Member" value={this.state.search} onChange={(e) => { this.handleChange(e, 'search') }} onKeyPress={(e) => { this.handleKeyDown(e); }} />
                                            <div className="list-group">
                                                {this.state.viewTable}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="card-header">Member Yang Dipilih</div>
                                        <div className="card-body">
                                            {this.state.viewTableMember}
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={(e) => { this.closeModal(e) }}>Batal</button>
                            <button type="button" className="btn btn-primary" onClick={(e) => { this.memberSendNotif(e) }}>Kirim Notif</button>
                        </div>

                    </div>
                </div>
            </div>

        </Fragment >
    }
}

export const ModalSearchCategoryProduct = class ModalSearchCategoryProduct extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            idmodal: 'modalSearchPromoForTemplate',
            search: '',
            viewTable: 'loading...',

            chooseData: props.chooseData,
            closeModal: props.closeModal
        }
    }
    componentDidMount() {
        document.getElementById('btn' + this.state.idmodal).click();
        this.previewData()
    }
    previewData() {
        const ctx = this
        const param = {
            search: this.state.search
        }
        RequestHttp('/api_admin_v1/data_category_product', param,
            function (data) {
                if (data.status === 'true') {
                    ctx.renderList(data.data)
                } else {
                    Toast(data.message, '');
                }
            }, function (error) {
                console.log(error);
            })
    }
    renderList(dataCategory) {
        const viewTemp = []
        for (let i = 0; i < dataCategory.length; i++) {
            viewTemp.push(<Fragment key={i}>
                <button type="button"
                    className="list-group-item list-group-item-action"
                    onClick={(e) => { this.chooseData(e, dataCategory[i]) }}>
                    {dataCategory[i].NameCategory}
                </button>
            </Fragment>)
        }
        this.setState({ viewTable: viewTemp })

    }
    handleChange(event, name) {
        this.setState({ [name]: event.target.value });
    }
    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.previewData();
        }
    }
    chooseData(e, dataPromo) {
        e.preventDefault()
        this.state.chooseData(dataPromo)
        document.getElementById(this.state.idmodal + 'Close').click();
    }
    closeModal(e) {
        e.preventDefault()
        this.state.closeModal()
    }
    render() {
        return <Fragment>

            <button id={'btn' + this.state.idmodal} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={'#' + this.state.idmodal} style={{ display: 'none' }}>
                Launch demo modal
            </button>

            <div className="modal" id={this.state.idmodal} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Cari Promo</h5>
                            <button id={this.state.idmodal + 'Close'} type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) => { this.closeModal(e) }}></button>
                        </div>
                        <div className="modal-body">

                            <input className="form-control mb-2" placeholder="Cari Produk" value={this.state.search} onChange={(e) => { this.handleChange(e, 'search') }} onKeyPress={(e) => { this.handleKeyDown(e); }} />
                            <div className="list-group">
                                {this.state.viewTable}
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </Fragment >
    }
}

export const ModalSearchCategoryPromo = class ModalSearchCategoryPromo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            idmodal: 'modalSearchCategoryPromo',
            search: '',
            viewTable: 'loading...',

            chooseData: props.chooseData,
            closeModal: props.closeModal
        }
    }
    componentDidMount() {
        document.getElementById('btn' + this.state.idmodal).click();
        this.previewData()
    }
    previewData() {
        const ctx = this
        const param = {
            search: this.state.search
        }
        RequestHttp('/api_admin_v1/data_category_promo', param,
            function (data) {
                if (data.status === 'true') {
                    ctx.renderList(data.data)
                } else {
                    Toast(data.message, '');
                }
            }, function (error) {
                console.log(error);
            })
    }
    renderList(dataCategory) {
        const viewTemp = []
        for (let i = 0; i < dataCategory.length; i++) {
            viewTemp.push(<Fragment key={i}>
                <button type="button"
                    className="list-group-item list-group-item-action"
                    onClick={(e) => { this.chooseData(e, dataCategory[i]) }}>
                    {dataCategory[i].NameCategory}
                </button>
            </Fragment>)
        }
        this.setState({ viewTable: viewTemp })

    }
    handleChange(event, name) {
        this.setState({ [name]: event.target.value });
    }
    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.previewData();
        }
    }
    chooseData(e, dataPromo) {
        e.preventDefault()
        this.state.chooseData(dataPromo)
        document.getElementById(this.state.idmodal + 'Close').click();
    }
    closeModal(e) {
        e.preventDefault()
        this.state.closeModal()
    }
    render() {
        return <Fragment>

            <button id={'btn' + this.state.idmodal} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={'#' + this.state.idmodal} style={{ display: 'none' }}>
                Launch demo modal
            </button>

            <div className="modal" id={this.state.idmodal} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Cari Kategori Promo</h5>
                            <button id={this.state.idmodal + 'Close'} type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) => { this.closeModal(e) }}></button>
                        </div>
                        <div className="modal-body">

                            <input className="form-control mb-2" placeholder="Cari Produk" value={this.state.search} onChange={(e) => { this.handleChange(e, 'search') }} onKeyPress={(e) => { this.handleKeyDown(e); }} />
                            <div className="list-group">
                                {this.state.viewTable}
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </Fragment >
    }
}