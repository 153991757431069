// const urlweb = `http://192.168.5.80:3000`;
// const urlweb = `http://192.168.9.127:5000`;
const urlweb = `http://pxa.co.id:5000`;


export const ipserver = urlweb;

export const Number_Format = function (ini) {
    ini = ini || 0;
    let number_string = ini.toString().replace(/[^.\d]/g, "").toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);
    if (ribuan) {
        const separator = sisa ? "," : "";
        rupiah += separator + ribuan.join(",");
    }
    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    return rupiah;
}

export const Number_Format_Decode = function (ini) {
    return ini.replace(/,/g, '');
}

export const CheckFileSize = function (fi) {
    if (fi.files.length > 0) {
        for (let i = 0; i <= fi.files.length - 1; i++) {

            const fsize = fi.files.item(i).size;
            const file = Math.round((fsize / 1024));
            // The size of the file. 
            if (file >= 2000) {
                return "Ukuran file terlalu besar, mohon pilih file dibawah 2mb"
            } else if (file < 100) {
                // return "Ukuran file terlalu kecil, mohon pilih file diatas 2mb";
                return "true"
            } else {
                return "true"
            }
        }
    }
}

export const SetCokkiee = function (sname, svalue) {
    const exdays = 365; // 1 tahun = 365 hari
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = sname + "=" + svalue + ";" + expires + ";path=/";
}

export const GetCokkiee = function (sname) {
    var name = sname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const ClearCokkiee = function () {
    SetCokkiee("username", '');
    SetCokkiee("token", '');
}

export const CheckAccount = function (ini) {
    return new Promise((resolve) => {
        fetch(urlweb + '/api_admin_v1/checkaccount', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "token": GetCokkiee('token')
            }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'true') {
                    resolve({ status: 'true' })
                } else {
                    SetCokkiee("username_admin", '');
                    SetCokkiee("token", '');
                    resolve({ status: 'false', message: 'Sesi anda telah habis, mohon login ulang' });
                }
            })
            .catch((error) => {
                resolve({ status: 'false', message: error });
                console.error('Error:', error);
            });
    })
}

export const RequestHttp = function (urlpathname, data, funcOK, funcError) {
    fetch(urlweb + urlpathname, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(response => response.json())
        .then(data => {
            funcOK(data);
        })
        .catch((error) => {
            funcError(error);
            // console.error('Error:', error);
        });
}


export const DateInputNow = function (type = 'date') {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;

    if (`${mm}`.length === 1) mm = `0${mm}`
    if ((`${dd}`).length === 1) dd = `0${dd}`
    const yyyy = today.getFullYear();

    if (type === 'month') {
        return `${yyyy}-${mm}`;
    } else if (type === 'datestart') {
        return `${yyyy}-${mm}-01`;
    } else {
        return `${yyyy}-${mm}-${dd}`;
    }
}


export const ModeViewApp = function () {
    const {
        innerWidth,
        // innerHeight
    } = window;
    if (innerWidth <= 425) {
        return 'mobile-view'
    } else if (innerWidth <= 768) {
        return 'tablet-view'
    } else {
        return 'desktop-view'
    }
}