import React, { Fragment } from 'react';
import {
  Route,
  Routes,
  useParams,
  useNavigate
} from 'react-router-dom';

import { GetCokkiee, RequestHttp } from '../utils/Main.js';
import { ConfirmDialog, AlertDialog, Toast, TableData, EntityPagging } from '../component/Component.js';


const nameEntityPagging = 'table_master_admin'
function App() {
  let navigate = useNavigate();

  // browse data
  class ViewMenu extends React.Component {
    constructor(props) {
      super(props);
      this.modelData = new EntityPagging(nameEntityPagging)
      this.state = {
        viewTable: 'loading...',
        search: this.modelData.get('search') || '',
      };
    }
    componentDidMount() {
      this.previewData();
    }
    handleChange(e, name) {
      this.setState({ [name]: e.target.value });
      if (name === 'searchcount') {
        this.modelData.set('count', e.target.value);
        this.previewData()
      }
    }
    deleteData(e, iddata) {
      e.preventDefault();
      const ctx = this;
      ConfirmDialog('', 'Anda yakin ingin menghapus data ini ?',
        (ctxdialog) => {
          RequestHttp('/api_admin_v1/delete_admin', {
            token: GetCokkiee('token'),
            iddata: iddata
          }, (data) => {
            ctxdialog.hide();
            setTimeout(() => {
              if (data.status === 'true') {
                ctx.modelData.reset()
                ctx.previewData();
                Toast(data.message, (ctxalert) => { ctxalert.hide(); });
              } else if (data.status === 'false') {
                Toast(data.message, '');
              } else {
                Toast('Terjadi Kesalahan', '');
              }
            }, 100);
          }, (error) => {
            console.log(error);
          });
        }, (ctxdialog) => { // action no
          ctxdialog.hide();
        }
      );
    }
    resetPassword(e, iddata) {
      e.preventDefault();
      const ctx = this;
      ConfirmDialog('', 'Anda yakin ingin mereset password data ini, password reset "0000" ?',
        (ctxdialog) => {
          RequestHttp('/api_admin_v1/resetpassword_admin', {
            token: GetCokkiee('token'),
            iddata: iddata
          }, (data) => {
            ctxdialog.hide();
            setTimeout(() => {
              if (data.status === 'true') {
                ctx.modelData.reset()
                ctx.previewData();
                Toast(data.message, (ctxalert) => { ctxalert.hide(); });
              } else if (data.status === 'false') {
                Toast(data.message, '');
              } else {
                Toast('Terjadi Kesalahan', '');
              }
            }, 100);
          }, (error) => {
            console.log(error);
          });
        }, (ctxdialog) => { // action no
          ctxdialog.hide();
        }
      );
    }
    previewData() {
      const ctx = this
      RequestHttp('/api_admin_v1/list_admin', {
        search: this.modelData.get('search'),
        searchcount: this.modelData.get('count'),
        index: this.modelData.get('index'),
        sortcolumn: this.modelData.get('sortcoumn'),
        sortorder: this.modelData.get('sortorder'),
      }, (data) => {
        if (data.status === 'true') {
          ctx.tableContent(data)
        } else if (data.status === 'false') {
          Toast(data.message, '')
        } else {
          Toast('Terjadi Kesalahan', '')
        }
      }, (error) => {
        console.log(error);
      });
    }
    tableContent(data) {
      const ctx = this;

      const tdAction = function (itemtemp) {
        return <Fragment>
          <button className="btn btn-outline-light btn-border-none me-1 mb-1" onClick={(e) => { ctx.edtiData(e, itemtemp.ID) }}>
            <span className="material-icons d-flex" style={{ color: 'grey' }}>
              edit
            </span>
          </button>
          <button className="btn btn-outline-light btn-border-none me-1 mb-1" onClick={(e) => { ctx.deleteData(e, itemtemp.ID) }}>
            <span className="material-icons d-flex" style={{ color: 'grey' }}>
              delete
            </span>
          </button>
          {/* <button className="btn btn-warning mb-1" onClick={(e) => { ctx.resetPassword(e, itemtemp.ID) }}>Reset Password</button> */}
        </Fragment>
      }
      const clickRow = function (e, item) {
        e.preventDefault();
        navigate(`/master_admin/input/${item.ID}`);
      }

      const tableData = {
        header: [
          { caption: 'Action' },
          { caption: 'Username', sort: '' },
          { caption: 'Nama Admin', sort: '' },
          { caption: 'Tanggal Edit', sort: '' },
          { caption: 'Dibuat Oleh', sort: '' },
        ],
        body: [
          { name: tdAction, type: 'view', css: 'td-actions' },
          { name: 'Username', click: true },
          { name: 'NameAdmin', click: true },
          { name: 'TimeUpdated1', click: true },
          { name: 'CreatedBy', click: true },
        ],
      }

      this.setState({
        viewTable: <TableData
          ctx={this}
          nameModelData={nameEntityPagging}
          tableData={tableData}
          dataset={data.data}
          ctpages={data.ctpages}
          ctdata={data.ctdata}
          clickRow={clickRow}
        />
      });
    }
    render() {
      return (
        <div className='background-input' style={{ padding: '30px' }}>

          {/* search */}
          <div className="row justify-content-between">
            <div className="col-12 col-md-3 mb-1">
              <button
                className="btn btn-warning text-light "
                onClick={(e) => {
                  e.preventDefault()
                  this.modelData.reset()
                  navigate(`/master_admin/input`);
                }}>
                Tambah Admin
              </button>
            </div>
            <div className="col-12 col-md-4 mb-1">
              <div className="input-group">
                <select className="form-select"
                  value={this.state.searchcount}
                  onChange={(e) => { this.handleChange(e, 'searchcount') }}
                  style={{ maxWidth: '80px' }}
                >
                  <option value="10" >10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <input type="text" className="form-control" placeholder="Pencarian"
                  value={this.state.search}
                  onChange={(e) => { this.handleChange(e, 'search') }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.modelData.reset()
                      this.modelData.set('search', this.state.search)
                      this.previewData()
                    }
                  }}
                />
                <button className='btn btn-warning text-light'
                  onClick={(e) => {
                    e.preventDefault()
                    this.modelData.reset()
                    this.previewData()
                  }}
                >
                  <span className="material-icons d-flex">
                    search
                  </span>
                </button>
              </div>
            </div>
          </div>
          {/* table content */}
          <div className="mt-3">
            {this.state.viewTable}
          </div>

        </div >
      )
    }
  }

  // inputing data
  class InputMenu extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        username: '',

        mode: props.mode,
        iddata: props.iddata || '',
        viewInputMenu: <div></div>
      };
    }
    componentDidMount() {
      if (this.state.mode === 'edit') {
        const ctx = this
        RequestHttp('/api_admin_v1/view_admin', {
          iddata: ctx.state.iddata
        }, (data) => {
          if (data.status === 'true') {
            if (data.data.length > 0) {
              ctx.setState({
                name: data.data[0].NameAdmin,
                username: data.data[0].Username,
                img: data.data[0].Img,
              })
            } else {
              AlertDialog('', 'Data tidak ditemukan', (ctxdialog) => {
                ctxdialog.hide();
                navigate(`/master_admin`);
              });
            }
          } else if (data.status === 'false') {
            Toast(data.message, '');
          } else {
            Toast('Terjadi Kesalahan', '');
          }
        }, (error) => {
          console.log(error);
        });
      }
      this.viewInputPrivilage();
    }
    handleChange(event, name) {
      this.setState({ [name]: event.target.value });
    }
    viewInputPrivilage() {
      // get menu privilage
      const ctx = this
      RequestHttp('/api_admin_v1/view_admin_privilage', {
        iddata: ctx.state.iddata
      }, (data) => {
        if (data.status === 'true') {
          const viewTempMenu = data.dataMenu.map((item, index) => {
            if (item.isStatus === 1) {
              return <label key={index} className="list-group-item">
                <input className="form-check-input me-1 mr-2" type="checkbox" name="edtMenu" value={item.CodeMenu} defaultChecked />
                {item.NameMenu}
              </label>
            } else {
              return <label key={index} className="list-group-item">
                <input className="form-check-input me-1 mr-2" type="checkbox" name="edtMenu" value={item.CodeMenu} />
                {item.NameMenu}
              </label>
            }
          })
          ctx.setState({ viewInputMenu: < ul className="list-group" >{viewTempMenu}</ul> })
        } else if (data.status === 'false') {
          Toast(data.message, '');
        } else {
          Toast('Terjadi Kesalahan', '');
        }
      }, (error) => {
        console.log(error);
      });
    }
    saveData(e) {
      e.preventDefault();
      ConfirmDialog('', 'Anda yakin ingin menyimpan data ini ?',
        (ctxdialog) => {
          var itemPrivilage = document.getElementsByName("edtMenu")
          let dataPrivilage = [];
          for (let i = 0; i < itemPrivilage.length; i++) {
            if (itemPrivilage[i].checked === true) dataPrivilage.push(itemPrivilage[i].value)
          }
          const urlinput = (this.state.mode === 'add') ? '/api_admin_v1/insert_admin' : '/api_admin_v1/update_admin'
          RequestHttp(urlinput, {
            token: GetCokkiee('token'),
            iddata: this.state.iddata,
            name: this.state.name,
            username: this.state.username,
            listPrivilage: dataPrivilage
          }, (data) => {
            ctxdialog.hide();
            setTimeout(() => {
              if (data.status === 'true') {
                Toast(data.message, '');
                navigate(`/master_admin`);
              } else if (data.status === 'false') {
                Toast(data.message, '');
              } else {
                Toast('Terjadi Kesalahan', '');
              }
            }, 100);
          }, (error) => {
            console.log(error);
          });
        }, (ctxdialog) => { // action no
          ctxdialog.hide();
        }
      );
    }
    render() {
      return (
        <div className='background-input' style={{ padding: '30px' }}>
          <h4 style={{ fontWeight: 'bold' }}>{(this.state.mode === 'add') ? 'Tambah' : 'Edit'} Admin</h4>
          <div className="card">
            <div className="card-body">
              <form onSubmit={(e) => { this.saveData(e) }}>

                {/* Form Inputing */}
                <div className="row">
                  <div className='col-12'>
                    <div>Nama Admin</div>
                    <div className="mt-1 mb-2">
                      <input type="text" className="form-control" placeholder="Masukan Nama Admin" value={this.state.name} onChange={(e) => { this.handleChange(e, 'name') }} required />
                    </div>
                  </div>

                  <div className='col-12'>
                    <div>Username</div>
                    <div className="mt-1 mb-2">
                      <input type="text" className="form-control" placeholder="Masukan Username Admin" value={this.state.username} onChange={(e) => { this.handleChange(e, 'username') }} required />
                    </div>
                  </div>

                  <div className='col-12 col-xl-5'>
                    <div>Hak Akses</div>
                    <div className="mt-1 mb-2">
                      {this.state.viewInputMenu}
                    </div>
                  </div>

                  <div className="col-12 mt-4 d-flex">
                    <button type="submit" className="btn btn-warning text-light me-1" >Simpan</button>
                    <button type="button" className="btn btn-outline-warning"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/master_admin`);
                      }}
                    >Batalkan</button>
                  </div>
                </div>
                {/* Form Inputing */}

              </form>
            </div>
          </div>
        </div >
      )
    }
  }
  function ViewEdit() {
    let { id } = useParams();
    return <InputMenu mode="edit" iddata={id} />
  }

  return (
    <Routes>
      <Route index element={<ViewMenu />} />
      <Route path={'/input'} element={<InputMenu mode="add" iddata="0" />} />
      <Route path={'/input/:id'} element={<ViewEdit />} />
    </Routes>
  )
}

export default App;